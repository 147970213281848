import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:theme.wls.mainBg.primary,
  },
  text_h1:{
    marginTop:"0",
    padding: "1em",
    fontFamily:theme.palette.typography,
    color: theme.wls.texts.title,
    fontSize:"20px",
  },
  form_container:{
    display:"flex",
    justifyContent:"center",
    alignItems:"stretch",
    boxShadow:"none",
    maxHeight: '80vh',
  },
  form:{
    width:"400px",
  },
  form_text_field:{
    borderBottom:"2px solid black",
    marginBottom:".5em",
    fontSize:"12px",
  },
  form_catalog_type: {
    display:"flex",
    justifyContent:"center",
    marginTop:".75em",
    marginBottom:".75em",
    fontSize:"12px"
  },
  button:{
    borderRadius:"0",
    margin:"1em 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"2em",
    },
  },
  select_container:{
    borderBottom:"2px solid black",
    margin:"1em 0",
    color:theme.palette.primary.dark,
    fontSize:"12px",
    '& > div':{
      width:"100%",
    }
  },
  radio_button_container:{
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    flexDirection:"Row",
    margin:"1em 0",
  },
  padding_text_field:{
    paddingBottom:"1em",
  },
  formControl:{
    marginBottom:"1em",
    minWidth: 80,
  },
  table_head:{
    '& tr':{
      backgroundColor:theme.palette.backgroundTable,
    },
  },
  table: {
    //maxHeight: '100px',
  },
  filter_current_catalog: {
    marginRight: "10px",
  },
  filter_container: {
    padding: "0 0 0 1.5em",
    display: "flex",
    alignItems: "stretch",
  },
  catalog_list_cards: {
    padding: "10px",
    display:"flex",
    justifyContent:"center",
    alignItems:"stretch",
    flexWrap: "wrap",
    boxShadow:"none",
    maxHeight: '80vh',
  },
  catalog_preview_container: {
    display:"flex",
    flexWrap:"wrap",
    border:"1px solid #FBFBFB",
  },
}));