import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import {useStyles} from './style.js';
import Grid from '@material-ui/core/Grid';
import UploadStockModal from 'components/modals/management/UploadStocks';
import RemoveStockModal from 'components/modals/management/RemoveStocks';
import SyncStockModal from 'components/modals/management/SyncStocks';
import TaskListModal from 'components/modals/management/TaskList';
import UploadImagesModal from 'components/modals/management/UploadImages';
import UploadColorsModal from 'components/modals/management/UploadColors';

export default function ManagementView() {
  const classes = useStyles();
  const [activeModal, setActiveModal] = useState("");

  return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>Management</h1>
      <Grid container className={classes.container}>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("upload_stock")}}>
              Upload stocks
            </Button>
          </div>
          </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("remove_stock")}}>
              Remove stocks
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("sync_stock")}}>
              Sync stocks
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("task_list")}}>
              Task List
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("image_upload")}}>
              Image upload
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("color_upload")}}>
              Color upload
            </Button>
          </div>
        </Grid>
      </Grid>
      <UploadStockModal active={(activeModal === "upload_stock")} onClose={()=>{setActiveModal(false)}}/>
      <RemoveStockModal active={(activeModal === "remove_stock")} onClose={()=>{setActiveModal(false)}}/>
      <TaskListModal active={(activeModal === "task_list")} onClose={()=>{setActiveModal(false)}}/>
      <UploadImagesModal active={(activeModal === "image_upload")} onClose={()=>{setActiveModal(false)}}/>
      <UploadColorsModal active={(activeModal === "color_upload")} onClose={()=>{setActiveModal(false)}}/>
      <SyncStockModal active={(activeModal === "sync_stock")} onClose={()=>{setActiveModal(false)}}/>
    </div>
  );
}
