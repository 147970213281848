import React from 'react';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default function ChangePasswordModal({active, onAdd, onClose, classes}) {
    const [password, setPassword] = React.useState("");

    const handleAdd = () => {
      onAdd(password);
    }

    const handleChangeText = (event) => {
        setPassword(event.target.value);
    };

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, enter a new password for this user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="password"
            label="Password"
            type="password"
            onChange={handleChangeText}
            value={password}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    )
}