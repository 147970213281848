export function requestStockUpdate() {
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/mgmt/stocks/sync',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         body: '{}'
    	}
    ).then(r=> {
        return r.json()
    });
}