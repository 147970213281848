import {apiCall, apiCallNoJSON} from 'library';

export async function getOrder(order_id) {
    return apiCall(`/api/order/get_wls/${order_id}`);
}

export async function getOrderList({archived, current_catalog, catalogs, seasons, sap_status, finished, begin, end}) {
    let query_string = '?';
    if (archived) {
        query_string+=`archived=true&`;
    }
    if (current_catalog) {
        query_string+=`current_catalog=true&`;
    }
    if (catalogs && catalogs.length > 0) {
        query_string+=`catalogs=${catalogs.join(",")}&`;
    }
    if (seasons && seasons.length > 0) {
        query_string+=`seasons=${seasons.join(",")}&`;
    }
    if (sap_status) {
        query_string+=`sap_status=${sap_status}&`;
    }
    if (finished) {
        query_string+=`finished=${finished}&`;
    }
    if (begin) {
        query_string+=`edited_gt=${begin}&`;
    }
    if (end) {
        query_string+=`edited_lt=${end}&`;
    }
    query_string = query_string.slice(0,-1);
    return apiCall(`/api/orders/list${query_string}`);
}
export async function getArchivedOrdersList() {
    return apiCall(`/api/orders/archived`);
}

export async function getOrdersFor(user_id) {
    return apiCall(`/api/orders/list_simple_for/${user_id}`);
}

export function getOrderListExcel(order_ids) {
    return apiCallNoJSON('/api/orders/download/list',
        {method: 'POST',
            body: JSON.stringify(
                {
                    "orders":  order_ids,
                }
            )
        }
    ).then( res => res.blob() )
      .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = "order_list.xlsx";
          a.click();
        });
}