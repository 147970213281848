export function uploadStockFile(file) {
    const FD  = new FormData();
    FD.append("stocks_file", file);
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/mgmt/upload_stock_async',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         body: FD
    	}
    ).then(r=> {
        return r.json()
    });
}