import React, {useState, useEffect, useRef} from 'react';

import { getTasksList } from 'library/management/task_status.js';
import { connect } from 'react-redux';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ReportIcon from '@material-ui/icons/Report';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null && delay !== undefined) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function mapStateToProps(state, ownProps) {
  const { dispatch } = state;
  return { dispatch: dispatch, ...ownProps }
}

const iconForStatus = (a_status) => {
  switch (a_status) {
    case "created": return <HourglassEmptyIcon />;
    case "WIP": return <TimelapseIcon />;
    case "failed": return <ReportIcon />;
    case "done": return <CheckIcon />;
    default:    return <HelpOutlineIcon />;
  }
}

export function TaskListModal({dispatch, active, onClose}) {
  const [queryStatus, setQueryStatus] = useState({timer: 10000});
  const [taskList, setTaskList] = useState([]);

  const onStatusUpdate = () => {
    getTasksList().then(e => {
      if (e.status === "success") {
        setTaskList(e.tasks);
      }
    })
  }

  const closeIt = () => {
    setQueryStatus({});
    onClose();
  }

  // Update timer
  useInterval(() =>{onStatusUpdate()}, queryStatus.timer);

  return (
    <Dialog open={active} onClose={closeIt} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Task list</DialogTitle>
      <DialogContent>
      <List>
        {taskList.map((task) => (
          <ListItem>
            <ListItemIcon>
              {iconForStatus(task.status)}
            </ListItemIcon>
            <ListItemText primary={task.class} secondary={
              <Grid container>
                <Grid item sm={2}>
                  <p>{task.progress}%</p>
                </Grid>
                <Grid item sm={8}>
                  <p>{task.error}</p>
                </Grid>
              </Grid>
            }
            />
          </ListItem>
        ))}
      </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeIt} color="default">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default connect(mapStateToProps)(TaskListModal)