import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ReturnOrderToSapModal(props) {
  const { open, order, onClose, setMessage } = props;
  const classes = styles();

  if (!open) {
    return <></>
  }
  const link = `${process.env.REACT_APP_WLS_API_URL}/api/order/download/excel/${order.id}`;

  return (
    <Dialog open={open} keepMounted onClose={onClose} >
        <DialogTitle>Return order</DialogTitle>
        <DialogContent className={classes.content}>
          Return order cannot be sent to SAP. Please download the XLS instead and upload it manually.
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button href={link} color="primary" onClick={onClose}>
            Download XLS
          </Button>
        </DialogActions>
      </Dialog>
  );
}
