import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Link } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import LockOpenIcon from '@material-ui/icons/LockOpen';

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    keepMounted
    autoFocus={false}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '& a': {
      width: '100%',
      height: '100%'
    },
  },
}))(MenuItem);

export const OrderActionMenuSimple = (props) =>  {
    const {menuElement, order, handleMenuClose, onItemClick} = props;

    if (menuElement === null || menuElement === undefined) {
        return <React.Fragment />
    }

    let items = [];
    if (order.sap_status === 0) {
        items = [
            {icon: <VisibilityIcon fontSize="small" />, text: "Order", link: `/linesheet/${order.id}`},
            {icon: <EditIcon fontSize="small" />, text: "Change address/channel", onClick: ()=>onItemClick("edit_address", order)},
            {icon: <DeleteIcon fontSize="small" />, text: "Delete order", onClick: ()=>onItemClick("delete_order", order)},
            {icon: <PictureAsPdfIcon fontSize="small" />, text: "Download full PDF", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/download/pdf/${order.id}`}
        ]
        if (order.items_count > 0) {
            items.push({icon: <SendIcon fontSize="small" />, text: "Send to SAP", onClick: ()=>onItemClick("send_to_sap", order)})
            items.push({icon: <PictureAsPdfIcon fontSize="small" />, text: "Download order PDF", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/download/pdf_orderonly/${order.id}`})
            items.push({icon: <PictureAsPdfIcon fontSize="small" />, text: "View order confirmation", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/send/confirmation/${order.id}`})
        }
        items.push({icon: <CloudDownloadIcon fontSize="small" />, text: "Download XLS", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/download/excel/${order.id}`})
    } else if (order.sap_status > 1) {
        items.push({icon: <LockOpenIcon fontSize="small" />, text: "Unlock order", onClick: ()=>onItemClick("unblock_order", order)})
    } else if (order.sap_status === 1) {
        items.push({icon: <PictureAsPdfIcon fontSize="small" />, text: "Download order PDF", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/download/pdf_orderonly/${order.id}`})
        items.push({icon: <PictureAsPdfIcon fontSize="small" />, text: "View order confirmation", link: `${process.env.REACT_APP_WLS_API_URL}/api/order/send/confirmation/${order.id}`})
    }
    items.push({icon: <FileCopyIcon fontSize="small" />, text: "Create copy", onClick: ()=>onItemClick("copy_order", order)})

    return (
        <StyledMenu
          anchorEl={menuElement}
          open
          onClose={handleMenuClose}
        >
            {items.map((item, index) => (
                <StyledMenuItem key={index} onClick={item.onClick}>
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                    <Link color="textPrimary" underline="none" href={item.link}>
                        <ListItemText primary={item.text} />
                    </Link>
                </StyledMenuItem>
            ))}
        </StyledMenu>

    );
}
