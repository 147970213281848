import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {CircularProgress, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CustomerSelector from 'components/CustomerSelector';
import {SalesOrgSelector, AddressSelector} from 'components/SelectOptions';
import { duplicateOrder } from 'library/order/create';
import {notnull} from 'library/wls_helper';
import {CloseRounded} from "@material-ui/icons";

const styles = makeStyles((theme) => ({
  form: {
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function CopyOrderModal(props) {
  const {open, order, onClose, setMessage} = props;
  const classes = styles();
  const [data, setData] = useState({name: ""});
  const [customer_selector, setCustomerSelector] = useState(false);
  const [is_copying, setIsCopying] = useState(false);
  const [is_copied, setIsCopied] = useState(false);

  React.useEffect(() => {
    if (open) {
      setData({name: ""});
    }
  }, [open]);
  
  const onCopyClick = () => {
    setIsCopying(true);
    let payload = {
      source: order.id,
      customer_id: data.customer.id,
      title: data.name
    }
    if (notnull(data.ship_to))
      payload.address = data.ship_to
    if (notnull(data.sales_org))
      [payload.sales_organization, payload.channel] = data.sales_org.split('_')
    duplicateOrder(payload).then(r => {
      if (r.status === "success") {
        setIsCopying(false);
        setMessage({message: "Order copied", severity: "success"});
        onClose();
      } else {
        setIsCopying(false);
        setIsCopied(false);
        console.log(r);
        alert(r.error);
      }
    });
  }
  const didCloseCustomerSelector = (customer) => {
    if (customer) {
      let default_addr = customer.addresses.length > 0 ? customer.addresses[0].sap_id : null;
      let default_so = customer.sales_organizations.length > 0 ? `${customer.sales_organizations[0].sales_organization}_${customer.sales_organizations[0].channel}` : null;
      //console.log(`Selected customer with default_addr=${default_addr} and default_so=${default_so}`)
      setData({...data, customer: customer, ship_to: default_addr, sales_org: default_so});
    }
    setCustomerSelector(false);
  }

  if (!open) {return <></>}

  return (
    <div className={classes.form}>
      <Dialog open={open} keepMounted onClose={onClose}>
        <DialogTitle>Duplicate order</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField required value={data.name} label="Order name" autoFocus name="name" onChange={e => setData({...data, name: e.target.value})} />
          <Button variant="outlined" onClick={e=>{setCustomerSelector(true)}}>Select customer</Button>
          {data.customer && 
            <div className={classes.customer_details_content}>
              <p>{data.customer.name}</p>
              <AddressSelector
                title="Address"
                onChange={e=>{setData({...data, ship_to: e})}}
                options={data.customer.addresses}
              />
              <SalesOrgSelector
                title="Sales organization / Channel"
                onChange={e=>{setData({...data, sales_org: e.id})}}
                options={data.customer.sales_organizations}
              />
            </div>
          }
        </DialogContent>
        <DialogActions>
          {(!is_copying && !is_copied) &&
            <React.Fragment>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={onCopyClick} disabled={data.customer === undefined} color="primary">
              Copy
            </Button>
          </React.Fragment>
          }
          { is_copying &&
            <Button color="primary">
              Copying <CircularProgress style={{height: "1em", width: "1em", marginLeft: "1em"}}  />
            </Button>
          }
          { is_copied &&
              <React.Fragment>
                <span>  Copied! </span>
                <Button onClick={onClose} color="primary">
                  close <CloseRounded style={{height: "1em", width: "1em", marginLeft: "0.5em"}} />
                </Button>
              </React.Fragment>
          }

        </DialogActions>
      </Dialog>
      <CustomerSelector show={customer_selector} onClose={didCloseCustomerSelector} />
    </div>
  );
}
