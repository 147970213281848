import React,{ useEffect } from 'react';
import { getCatalogList } from 'library/catalog/get';
import {FormGroup, TextField, Button, FormControlLabel, MenuItem, Select} from '@material-ui/core';
import { useStyles } from './Style';
import InputLabel from '@material-ui/core/InputLabel';
import { Paper, CircularProgress} from '@material-ui/core';
import PrimaryCheckbox from "../../../components/primaryCheckbox";
import FormControl from "@material-ui/core/FormControl";
import {addBatch, deleteBatch, editBatch} from "../../../library/catalog/batch";

export default function CatalogBatchUpdate() {
    const classes = useStyles();
    const [catalogList, setCatalogList] = React.useState([]);
    const [selected_catalog, setSelectedCatalog] = React.useState([]);
    const [selected_action, setSelectedAction] = React.useState([]);
    const [catalog_file, setCatalogFile] = React.useState();
    const [is_uploading, setIsUploading] = React.useState(false);
    const [is_created, setIsCreated] = React.useState(false);

    useEffect(() => {
        setIsUploading(true);
        getCatalogList().then(e => {
            setIsUploading(false);
            setCatalogList(e);
        });
    },[]);


    const helperAlert = (response) => {
        setIsUploading(false);
        if (response.status === "success") {
            alert("Awesome! Updated successfully!");
        } else if (response.status === "error") {
            alert(response.error);
        } else {
            alert("Problem batch update catalog, try again later!");
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        if(selected_catalog.length < 1){
            alert("Select some catalog");
            return;
        }
        setIsUploading(true);
        if(selected_action === "add") {
            addBatch(selected_catalog, catalog_file).then(helperAlert)
        }
        if(selected_action === "edit"){
            editBatch(selected_catalog, catalog_file, is_created).then(helperAlert)
        }
        if(selected_action === "delete"){
            deleteBatch(selected_catalog, catalog_file, is_created).then(helperAlert)
        }
    }
    function handleFileAdded(event) {
        event.preventDefault();
        if (event.target.files.length === 0) {return;}
        let file = event.target.files[0];
        setCatalogFile(file);
    }

    function didChangeAction (event) {
        setSelectedAction(event.target.value);
        setIsCreated(false);
    }

    function didChangeCatalog(catalog_id, checked) {
        let is_included = selected_catalog.includes(catalog_id)
        if (is_included && !checked ) {
                let index = selected_catalog.indexOf(catalog_id);
                setSelectedCatalog([
                    ...selected_catalog.slice(0, index),
                    ...selected_catalog.slice(index + 1)
                ])
        }
        if ( !is_included && checked) {
            setSelectedCatalog([...selected_catalog, catalog_id])
        }
    }

    return (
        <div className={classes.root}>
            <h1 className={classes.text_h1}>Batch update</h1>
            <div className={classes.form_container}>
                <Paper hidden={!is_uploading}>
                    <h2>Loading</h2>
                    <CircularProgress />
                </Paper>
                <form hidden={is_uploading} className={classes.form} onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormControl className={classes.formControl}><InputLabel >Select action</InputLabel>
                        <Select value={selected_action} onChange={didChangeAction} required={true}>
                            <MenuItem value={"add"}>
                                Add new products
                            </MenuItem>
                            <MenuItem value={"edit"}>
                                Edit products info
                            </MenuItem>
                            <MenuItem value={"delete"}>
                                Delete products
                            </MenuItem>
                        </Select>
                        </FormControl>
                        <div>
                                <InputLabel >Catalog</InputLabel>
                                    {catalogList.map( catalog => (
                                        <FormControlLabel key={catalog.id} control={
                                            <PrimaryCheckbox
                                                onChange={e => didChangeCatalog(catalog.id, e.target.checked)}
                                                name={catalog.name} />
                                        } label={catalog.name} />
                                    ))}

                        </div>
                        <TextField  required={true} className={[classes.form_text_field].join(" ")} label="Load excel" type="file" onChange={handleFileAdded} InputLabelProps={{shrink: true}} inputProps={{"accept": ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}} />
                        { selected_action === "edit" &&
                            <FormControlLabel   control={
                            <PrimaryCheckbox name={"is_create"} value={is_created} onChange={(e) => setIsCreated(e.target.checked)} />
                            } label={"Create if doesn’t exist"} />
                        }


                        <Button variant="contained" className={classes.button} type="submit" >
                            Apply!
                        </Button>
                    </FormGroup>
                </form>
            </div>
        </div>
    );
}
