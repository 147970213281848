
function getFormData(catalog_ids, catalog_file, override = null ){
    const _FD  = new FormData();
    _FD.append("catalog_ids", catalog_ids);
    _FD.append("file_path", catalog_file);
    if (override !== null) {
        _FD.append("override", override)
    }
    return _FD;
}

export function addBatch(catalog_ids, catalog_file) {
    const FD  = getFormData(catalog_ids, catalog_file)
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/garments/upload',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         body: FD
    	}
    ).then(r=> {
        return r.json()
    });
}

export function deleteBatch(catalog_ids, catalog_file) {
    const FD  = getFormData(catalog_ids, catalog_file)
    return fetch(process.env.REACT_APP_WLS_API_URL + '/api/garments/delete',
        {method: 'POST',
            mode: 'cors',
            credentials: "include",
            body: FD
        }
    ).then(r=> {
        return r.json()
    });
}

export function editBatch(catalog_ids, catalog_file, override = false) {
    const FD  = getFormData(catalog_ids, catalog_file, override)
    return fetch(process.env.REACT_APP_WLS_API_URL + '/api/garments/edit',
        {method: 'POST',
            mode: 'cors',
            credentials: "include",
            body: FD
        }
    ).then(r=> {
        return r.json()
    });
}