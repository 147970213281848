import React,{ useEffect, Fragment } from 'react';
import { getCatalogList } from 'library/catalog/get';
import { createOrder } from 'library/order/create';
import { FormGroup, TextField, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import ErrorModal from 'components/errorModal';
import AlertModal from 'components/alertModal';
import {WLSContext} from 'wls_context.js';
import {useStyles} from './Style_create.js';

export default function LinkGenerator() {
  const classes = useStyles();
  const {ctx} = React.useContext(WLSContext);
  const [catalogList, setCatalogList] = React.useState([]);
  const [userFields, setUserFields] = React.useState({name:"",email:"",catalog:"",is_wholesale:"true", send_email:true});
  const [errorStatus, setErrorStatus] = React.useState({failed:false, message:"",});
  const [alertStatus, setAlertStatus] = React.useState({show:false, message:"",});
  useEffect(() => {
    getCatalogList().then(e=> {
      setCatalogList(e);
    })
  },[]);

  function handleSubmit(event) {
    event.preventDefault();
    if (process.env.REACT_APP_WLS_PREVENT_EMAIL === "yes") {
      userFields.send_email = false;
    }
    createOrder(userFields.name, userFields.email, ctx.user.id, userFields.catalog, userFields.is_wholesale, userFields.send_email).then(e=> {
      if(e.status === "success"){
        setAlertStatus({show:true, message:<Fragment>Order created: <a href={"/linesheet/" + e.order_id}>view it</a></Fragment>});
      }else{
        setErrorStatus({failed:true, message:e.error,});
      }
    })
  }

  var currentCatalog = {};

  let catalogItems = [];

  for (var i = 0; i < catalogList.length; i++) {
    var catalog = catalogList[i];
    catalogItems.push(<MenuItem key={i} value={catalog.id}>{catalog.name} ({catalog.num_items})</MenuItem>);
    if(userFields.catalog === catalog.id){
      currentCatalog = catalog;
    }
  }

  const handleErrorClose = () => {
    setErrorStatus({failed:false, message:"",});
  };

  const handleAlertClose = () => {
    setAlertStatus({show:false, message:"",});
  };
  return (
    <div>
      <h1 className={classes.text_h1}>Link generator</h1>
      <div className={classes.form_container}>
        <form className={classes.form} onSubmit={handleSubmit}>
        <FormGroup>
          <TextField label="Name" className={classes.form_text_field} onChange={e => setUserFields({...userFields,name:e.target.value})} />
          <TextField label="Email" className={classes.form_text_field} onChange={e => setUserFields({...userFields,email:e.target.value})} />
          <div className={classes.select_container}>
            <InputLabel id="catalog-select-label" className={classes.form_label}>Catalog</InputLabel>
            <Select labelId="catalog-select-label" id="demo-simple-select" value={userFields.catalog} onChange={e => setUserFields({...userFields,catalog:e.target.value})}>
              {catalogItems}
            </Select>
          </div>
          <div className={classes.cataloginfo}>
            <span>{currentCatalog.brand}</span> <span>{currentCatalog.season}</span>
          </div>
          <RadioGroup className={classes.radio_button_container} value={userFields.is_wholesale} onChange={e => setUserFields({...userFields,is_wholesale:e.target.value})}>
            <FormControlLabel value="true" control={<Radio />} label="Wholesale" />
            <FormControlLabel value="false" control={<Radio />} label="Store" />
          </RadioGroup>
          <FormControlLabel control={<PrimaryCheckbox checked={userFields.send_email} onChange={e => setUserFields({...userFields,send_email:e.target.checked})} color="primary"/>} label="Send email"/>
          <Button className={classes.button} type="submit" onClick={handleSubmit}>
            Create
          </Button>
        </FormGroup>
        </form>
      </div>
      <ErrorModal title="Oops!" body={errorStatus.message} open={errorStatus.failed} onClose={handleErrorClose}/>
      <AlertModal title="Order created" body={alertStatus.message} open={alertStatus.show} onClose={handleAlertClose}/>
    </div>
  );
}
