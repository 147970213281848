
export const check_filters = (product, filter, lines, wanted) => {
  let filter_passed = true;
  if (filter.query !== undefined && filter.query !== null)
    filter_passed = JSON.stringify(product).toLowerCase()
    .includes(filter.query.toLowerCase());
  if (!filter.selected)
    return filter_passed;
  let has_quantity = productIsInLines(product, lines);
  let is_wanted = productIsInWanted(product, wanted);
  return filter_passed && (has_quantity || is_wanted);
}

function productIsInLines(product, lines) {
  return Object.keys(lines).includes(product.generic);
}

function productIsInWanted(product, wanted=[]) {
  for (let i = 0; i < product.colors.length; i++) {
    let color = product.colors[i].number;
    if (wanted.includes(product.generic+color)) {
    	return true;
    } else {
    	for (var j = 0; j < product.sizes.length; j++) {
        let size = product.sizes[j];
        if (wanted.includes(product.generic+color+size)) {
        	return true;
        }
      }
    }
  }
  return false;
}