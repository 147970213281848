import {apiCall} from 'library';

export function deleteOrder(id) {
    return apiCall('/api/order/delete', 
        {method: 'DELETE',
         body: JSON.stringify(
                {
                "id": id
                }
            )
        }
    );
}