import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from './style';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { getCatalogList } from 'library/catalog/get';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderListFilterModal ({filter, onFilterChange, show, onClose}) {
  const [catalogs, setCatalogs] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getCatalogList().then(e=> {
        setCatalogs(e);
        let new_seasons = e.map(x=>x.season);
        setSeasons([...new Set(new_seasons)]);
      });
  },[]);
  
  const didChangeOlderThan = (e => {
    let date = (new Date(e.target.value)).getTime() / 1000;
    onFilterChange({...filter, "begin": date});
  });
  const didChangeNewerThan = (e => {
    let date = (new Date(e.target.value)).getTime() / 1000;
    onFilterChange({...filter, end: date});
  });

  const handleChangeCatalog = (e=> {
    onFilterChange({...filter, catalogs: e.target.value, current_catalog: false});
  });
  const handleChangeSeason = (e=> {
    onFilterChange({...filter, seasons: e.target.value});
  });

  return (
    <Dialog open={show} TransitionComponent={Transition} keepMounted onClose={onClose} maxWidth="md" fullWidth={false}>
        <DialogTitle id="alert-dialog-slide-title">Filter orders</DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.filter_container_modal}>
            <div className={classes.modalFilterBox}>
              <FormControl className={classes.formControlModal}>
                <InputLabel id="catalog-selector-label">Catalogs</InputLabel>
                <Select
                  labelId="catalog-selector-label"
                  id="catalog-selector"
                  multiple
                  value={filter.catalogs}
                  className={classes.mdSelect}
                  onChange={handleChangeCatalog}
                  input={<Input />}
                  renderValue={(selected) => `${selected.length} catalogs`}
                  MenuProps={MenuProps}
                >
                  {catalogs.map((catalog) => (
                    <MenuItem key={catalog.id} value={catalog.id}>
                      <Checkbox checked={filter.catalogs.indexOf(catalog.id) > -1} />
                      <ListItemText primary={catalog.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControlModal}>
                <InputLabel id="seasons-selector-label">Seasons</InputLabel>
                <Select
                  labelId="seasons-selector-label"
                  id="seasons-selector"
                  multiple
                  value={filter.seasons}
                  className={classes.mdSelect}
                  onChange={handleChangeSeason}
                  input={<Input />}
                  renderValue={(selected) => `${selected.length} seasons`}
                  MenuProps={MenuProps}
                >
                  {seasons.sort((a,b)=>(a.localeCompare(b))).map((season) => (
                    <MenuItem key={season} value={season}>
                      <Checkbox checked={filter.seasons.indexOf(season) > -1} />
                      <ListItemText primary={season} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.dateFilterBox}>
              <TextField label="After" size="small" type="date" onChange={didChangeOlderThan} className={classes.dateFilterInput} InputLabelProps={{shrink: true,}}/>
              <TextField label="Before" size="small" type="date" onChange={didChangeNewerThan} className={classes.dateFilterInput} InputLabelProps={{shrink: true,}}/>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}
