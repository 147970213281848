import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { getCustomerList } from 'library/user/get';
import WLSTable from 'components/WLSTable.jsx';

const styles = makeStyles((theme) => ({
  form: {
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerSelector({show, onClose}) {
  const classes = styles();
  const [filter, setFilter] = useState({});
  const [customers, setCustomerList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    if (loaded) { return; }
    getCustomerList({include_addresses: true}).then(e=> {
      setLoaded(true);
      setCustomerList(e);
    })
  },[customers, loaded]);

  const onCancel = () => {
    onClose();
  }

  const onCustomerSelect = (customer) => {
    onClose(customer);
  }

  const shouldShow = customer => {
    if (filter.query)
      if (!JSON.stringify(customer).toLowerCase().includes(filter.query.toLowerCase()))
        return false;

    if (filter.in_sap) {
      return customer.sap_id === null;
    }
    return true;
  };

  const didChangeQuery = (e) => {
    setFilter({...filter, query: e.target.value});
  }

  const columns = [
    { id: 'name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'email', align: "left", disablePadding: true, label: 'Email' },
    { id: 'sap_id', align: "center", disablePadding: true, label: 'SAP ID'},
    { id: 'actions', align: "right", disablePadding: false, label: 'Actions', rowContent: (c) => (
      <Button variant="outlined" onClick={e=>onCustomerSelect(c)}>Select</Button>
    )},
  ];

  return (
    <div className={classes.form}>
      <Dialog open={show}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">Chose a customer</DialogTitle>
        <DialogContent>
          <div className={classes.filter_container}>
            <TextField className={classes.search_form} label="Search" variant="outlined" onChange={didChangeQuery}/>
          </div>
          <WLSTable
            classes={classes}
            rows={customers}
            columns={columns}
            shouldShowRow={shouldShow}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
