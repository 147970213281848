import React, {useEffect, useState} from 'react';

import { getCatalogList } from 'library/catalog/get';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PrimaryCheckbox from "../../primaryCheckbox";
import { Box, Select, MenuItem } from "@material-ui/core";
import {HighlightOff, RotateLeft } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';

import {priceslists, warehouses} from 'library/constants.js';

export default function ChangeProfileUserModal({active, onEdit, onClose, onChangePassword, classes, user}) {

    const [ thisUser, setUser] = useState(user);
    const [catalogList, setCatalogList] = useState([]);
    //const [reloadCatalog, setReloadCatalog] = useState(0);

    const handleAdd = (e) => {
        e.preventDefault();
        onEdit(thisUser);
    }

    const handleChangeText = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        thisUser[name] = value;
        setUser({...thisUser});
    };

    
    function handleChangeSap (target){ 
        // No renderizo para que deje escribir..
        thisUser.sap_id = target.value;
    }
    function renderUser(){
        setUser({...thisUser});
    }

    const unLinkSap_id = () =>{
        thisUser.sap_id = null;
        setUser({...thisUser});
    }

    function handleChangeCatalog (catalog, checked) {
        if( checked ){
            thisUser.catalogs = [ ...thisUser.catalogs, catalog ]
        }else {
            let index = thisUser.catalogs.indexOf(catalog);
            thisUser.catalogs = [ ...thisUser.catalogs.splice(0, index), ...thisUser.catalogs.splice(index+1) ] 
        }
        setUser({...thisUser});
    }

    const didChangePriceList = (e) => {
        let pl = e.target.value;
        thisUser.pricelist = pl;
        setUser({...thisUser});
    }

    const didChangeWarehouse = (e) => {
        let pl = e.target.value;
        thisUser.warehouse = pl;
        setUser({...thisUser});
    }

    useEffect(() => {
        getCatalogList().then(setCatalogList);
    }, []);


  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit profile</DialogTitle>
        <form onSubmit={handleAdd}>
            <DialogContent>
              <DialogContentText>
                Please, edit profile for this user.
              </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="name"
                        type="text"
                        onChange={handleChangeText}
                        value={thisUser.name}
                        disabled={thisUser.sap_id ? true : false }

                    />
                <TextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="email"
                    onChange={handleChangeText}
                    value={thisUser.email}
                />
                { ! thisUser.sap_id ?
                    <TextField
                        margin="dense"
                        name="sap_id"
                        label="SAP ID"
                        type="text"
                        onBlur={renderUser}
                        onChange={e => handleChangeSap (e.target) }
                    />
                    :
                    <>
                     <TextField
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <HighlightOff />
                            </InputAdornment>
                            ),
                        }}
                        margin="dense"
                        name="sap_id"
                        label="SAP ID"
                        type="text"
                        onClick={unLinkSap_id}
                        value={thisUser.sap_id}
                        disabled
                    />
                    {/* <Button onClick={unLinkSap_id} color="secondary"> <HighlightOff></HighlightOff>  SAP: {thisUser.sap_id} </Button> */}
                    </>
                }
                <InputLabel id="pricelistLabel">Price list</InputLabel>
                <Select
                    labelId="pricelistLabel"
                    id="pricelist-select"
                    value={thisUser.pricelist ? thisUser.pricelist : ""}
                    onChange={didChangePriceList}
                  >
                    <MenuItem value="None">None</MenuItem>
                    {priceslists.map(e=> (
                      <MenuItem key={e} value={e}>{e}</MenuItem>
                    ))}
                </Select>
                <InputLabel id="warehouseLabel">Warehouse</InputLabel>
                <Select
                    labelId="warehouseLabel"
                    id="warehouse-select"
                    value={thisUser.warehouse ? thisUser.warehouse : ""}
                    onChange={didChangeWarehouse}
                  >
                    <MenuItem value="None">None</MenuItem>
                    {warehouses.map(e=> (
                      <MenuItem key={e} value={e}>{e}</MenuItem>
                    ))}
                </Select>
                <Box className={classes.rolesContainer}>
                    <InputLabel id="catalog-select-label" className={classes.rolesLabel}>Catalogs</InputLabel>
                    {catalogList.map(catalog =>(
                        <FormControlLabel key={catalog.id} control={
                            <PrimaryCheckbox
                                checked={thisUser.catalogs.includes(catalog.id) ? true  : false}
                                name={catalog.name}
                                onChange={e => handleChangeCatalog(catalog.id, e.target.checked)}
                                color="primary"
                            />} label={catalog.name} className={classes.roleCheckbox}/>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onChangePassword} color="default">
                    <RotateLeft/> Password
                </Button>
              <Button onClick={onClose} color="default">
                Cancel
              </Button>
              <Button type={"submit"} color="primary">
                Update
              </Button>
            </DialogActions>
          </form>
      </Dialog>
    )
}