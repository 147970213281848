import React, {useState} from 'react';

import { removeAllStocks } from 'library/management/remove_stocks.js';
import { connect } from 'react-redux';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


function mapStateToProps(state, ownProps) {
  const { dispatch } = state;
  return { dispatch: dispatch, ...ownProps }
}

export function RemoveStockModal({dispatch, active, onClose}) {
  const [uploadStatus, setUploadStatus] = useState({"status": "none"});
  
  const handleAdd = (e) => {
    e.preventDefault();
    setUploadStatus({"status": "uploading"});
    removeAllStocks().then((e)=>{
        if (e.status === "success") {
            setUploadStatus({"status": "none"})
            onClose();
            dispatch({type:"SHOW_MESSAGE", payload: {message: "Removed stocks", severity: "success"}})
        } else {
            setUploadStatus({"status": "none"})
            dispatch({type:"SHOW_MESSAGE", payload: {message: `Error removing stocks: ${e.error}`, severity: "error"}})
        }
    }).catch((err)=>{
        setUploadStatus({"status": "none"})
        dispatch({type:"SHOW_MESSAGE", payload: {message: "error", severity: "error"}})
    })
  }

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Remove stocks</DialogTitle>
        {uploadStatus.status === "none" && 
        <form>
            <DialogContent>
              <DialogContentText>
                Are you sure? This will remove the stocks for all products.
              </DialogContentText>
                          
            </DialogContent>
            <DialogActions>
                  <Button onClick={onClose} color="default">
                    Cancel
                  </Button>
              <Button 
                type={"submit"}
                color="primary"
                onClick={handleAdd}
              >
                Yes, remove!
              </Button>
            </DialogActions>
          </form>
        }
          {uploadStatus.error !== undefined && 
            <p>{uploadStatus.error}</p>

          }
          {uploadStatus.status === "uploading" && 
          <DialogContent>
            <p>Uploading</p>
          </DialogContent>
        }
      </Dialog>
    )
}
export default connect(mapStateToProps)(RemoveStockModal)