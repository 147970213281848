
import { makeStyles } from '@material-ui/core';

const drawerWidth = 256;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  main: {
    flex: 1,
    padding: "2em",
    backgroundColor: theme.wls.mainBg.secondary,
    /*
    '& > div':{
      backgroundColor: theme.wls.mainBg.primary,
      padding:"2em",
      minHeight:"100%",
    },
    */
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer_custom:{
      backgroundColor: theme.wls.navigation.backgroundColor,
  },
  item: {
    paddingTop: ".5em",
    paddingBottom: ".5em",
    paddingLeft: "12px",
    color: theme.palette.primary.light,
  },
  itemLogo:{
    '&:hover,&:focus': {
      backgroundColor: theme.wls.navigation.backgroundColor,
      borderLeft:"none",
      transition:"none",
    },
  },
  itemCategory: {
    backgroundColor: theme.wls.navigation.backgroundColor,
    paddingTop: ".5em",
    paddingBottom: ".5em",
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
  },
  firebase: {
    fontSize: 24,
    //color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.details,
    "& .MuiListItemText-primary": {
      //color: theme.palette.primary.contrastText,
    },
    "& .MuiListItemIcon-root": {
      //color: theme.palette.primary.contrastText,
    },
    "& > div":{
      backgroundColor: theme.palette.backgroundDetails,
      borderLeft:"4px solid #1C83DC",
      transition:".3s all ease",
    },
  },
  itemPrimary: {
    fontSize: 16,
    color:theme.palette.primary.contrastText,
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
    color:theme.palette.primary.contrastText,
  },
  list: {
  },
  collapse: {
    backgroundColor: theme.wls.navigation.backgroundColor,
    "& .MuiListItem-button":{
      paddingLeft: "36px",
      borderLeft:`4px solid ${theme.wls.navigation.backgroundColor}`,
      '&:hover,&:focus': {
        backgroundColor: theme.palette.backgroundDetails,
        borderLeft:"4px solid #1C83DC",
        transition:".3s all ease",
      },
    }
  },
  logo: {
    fill:theme.palette.primary.contrastText,
    width:"100%",
    padding:"1em",
  },
}));