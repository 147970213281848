import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core';
import { unblockOrder } from 'library/order/update';
import { connect } from 'react-redux';

const styles = makeStyles((theme) => ({
  form: {
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function mapStateToProps(state, ownProps) {
  const { order_menu, dispatch } = state;
  return { show: Boolean(order_menu.unblock_order_show), order: order_menu.order, dispatch: dispatch }
}

function UnblockOrderModal({show, order, dispatch}) {
  const classes = styles();

  const onClose = () => {
    dispatch({type: "UNBLOCK_ORDER_DISMISS"});
  }
  const onDeleteClick = () => {
    unblockOrder(order.id).then(response => {
      if (response.status === "success") {
        dispatch({type: "SHOW_MESSAGE", "payload": {"message": "Order unlocked", severity: "success"}});
        dispatch({type: "RELOAD_TABLE"});
      } else {
        dispatch({type: "SHOW_MESSAGE", "payload": {"message": response.error, severity: "error"}});
        dispatch({type: "UNBLOCK_ORDER_DISMISS"});
      }
    });
  }
  if (!show) {
    return <></>
  }

  return (
    <div className={classes.form}>
      <Dialog open={show} TransitionComponent={Transition} keepMounted onClose={onClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Unlock order</DialogTitle>
        <DialogContent className={classes.content}>
          Are you sure you want to unlock the order named {order.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onDeleteClick} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps)(UnblockOrderModal)