import React, { useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import {styles} from 'styles/components/linesheetSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import SearchField from 'components/SearchField';
import {summaryFor} from './functions';
import MarkAsFinishedModal from './MarkAsFinishedModal';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {updateOrderFinished} from "../../../library/order/update";
import RestoreIcon from '@material-ui/icons/Restore';
import {store} from "../../../wls_context";

function mapStateToProps(state, ownProps) {
  const { wls, dispatch } = state;
  return { garments: wls.garments, lines: wls.lines, wanted: wls.wanted, catalog: wls.catalog, finished: wls.finished, dispatch: dispatch, service_date: wls.service_date, order_id: wls.id }
}

function Summary({garments, lines, wanted, catalog, service_date, order_id, finished, dispatch}) {
  const classes = styles();
  const [summary, setSummary] = React.useState({drops: {}});
  const [only_selected, setOnlySelected] = React.useState(false);
  const [service_date_controlled, setServiceDate] = React.useState(service_date);
  const [query_text, setQueryText] = React.useState("");
  const [mark_as_finished_modal, setMarkAsFinishedModal] = React.useState(false);
  const [is_finished, setLocalFinished] = React.useState(finished);
  const [is_loading, setIsLoading] = React.useState(false);
  const [is_fail, setIsFail] = React.useState(false);

  useEffect(() => {
    if (catalog){
        setSummary(summaryFor(garments, catalog, lines, wanted));
        setServiceDate(service_date);
        setLocalFinished(finished)
    }
    setLocalFinished(finished)// eslint-disable-next-line
  }, [garments, lines, catalog, wanted, finished]);

  const onFilterChange = e => {
    dispatch({type: 'SET_FILTER', filter: e});
  }

  useEffect(() => {
    onFilterChange({'selected': only_selected, 'query': query_text});
    // eslint-disable-next-line
  }, [only_selected, query_text]);
  

  const onFinishedOrder = e => {
    // dispatch({type: 'SET_FINISHED', customer_finished: e.target.checked});
    const status = e.target.checked;
    setIsLoading(true);
    updateOrderFinished(order_id, status).then((e)=>{
      if(e.status === "success"){
        setLocalFinished(status);
        setIsLoading(false);
      }else{
        store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": e.error, severity: "error"}})
        setIsLoading(false);
        setIsFail(true)
      }
    }).catch((e)=>{
      store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": e.toString(), severity: "error"}})
      setIsLoading(false);
      setIsFail(true)
    })
  }
  const onServiceDateChange = e => {
    setServiceDate(e);
    dispatch({type: 'SET_SERVICE_DATE', date: e});
  }

  const onSaveClick = e => {
    store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": "Saving changes", severity: "info"}})
    dispatch({type: 'SEND_ORDER'});
  }
  const didConfirmAsFinished = e => {
    setMarkAsFinishedModal(false);
    onFinishedOrder();
  }
  function didChangeServiceDate(event) {
    let date = new Date(event.target.value);
    if (date - Date.now() < 0) {
      return alert("Service date cannot be before current date");
    }
    onServiceDateChange(date / 1000);
  }
  let service_date_obj = service_date_controlled ? new Date(service_date_controlled * 1000) : null;
  let service_date_string = service_date_obj !== null ? service_date_obj.toISOString().slice(0, 10) : "";

  return (
    <>
    <h1 className={classes.text_h1}>Line sheet</h1>
    <p className={classes.text_base}>
      Check if the order is correct and accept to confirm it.
    </p>
    <SearchField
      className={classes.search_form}
      onChange={e=>setQueryText(e.target.value)}
      value={query_text}
    />
    <FormControlLabel
     className={classes.onlySelectedControl}
     control={<PrimaryCheckbox checked={only_selected} onChange={e=>setOnlySelected(e.target.checked)} color="primary"/>}
     label="Only selected"
    />
    <div className={classes.summary}>
      <SummaryTextDetail classes={classes} text="Number of products: " value={summary.count} />
      { summary.wanted ? <SummaryTextDetail classes={classes} text="Number of not sure: " value={summary.wanted} /> : null }
      { summary.price ? <SummaryTextDetail classes={classes} text="Total price: " value={`${summary.price ? summary.price.toFixed(2) : ""} ${summary.currency}`} /> : null }
      <SummaryTextDetail classes={classes} text="Total quantity: " value={summary.quantity} />
    </div>
    <div className={classes.drop_summary}>
      {Object.entries(summary.drops).map(([drop_name, drop_quantity])=>(
        <SummaryTextDetail key={drop_name} classes={classes} text={`${drop_name}: `} value={drop_quantity} />
      ))}
    </div>
    <TextField
      label="Preferred service date"
      type="date"
      onChange={didChangeServiceDate}
      className={classes.textField}
      value={service_date_string}
      InputLabelProps={{shrink: true,}}
    />
    <div className={classes.downloadButtonBox}>
      <Button
        variant="contained"
        className={clsx(classes.button, classes.buttonExcel)}
        href={`${process.env.REACT_APP_WLS_API_URL}/api/order/download/excel/${order_id}`}
        startIcon={<GetAppIcon />}
      >
        Excel
      </Button>
      <Button
        variant="contained"
        className={clsx(classes.button, classes.buttonPDF)}
        href={`${process.env.REACT_APP_WLS_API_URL}/api/order/download/pdf_orderonly/${order_id}`}
        startIcon={<PictureAsPdfIcon />}
      >
        PDF
      </Button>
    </div>

    { ( summary.quantity > 0 && ! is_fail ) &&
      <FormControlLabel
       className={classes.finishedCheckbox}
       control={<PrimaryCheckbox checked={is_finished} onChange={onFinishedOrder} color="primary" />}
       label={is_loading ? "Updating... "  : "Finished"}
      />
    }
    { is_fail &&
      <FormControlLabel
          className={classes.finishedCheckbox}
          style={{padding: "1em"}}
          control={<RestoreIcon  style={{paddingRight: "0.5em"}}/>}
          label={"Failed update"}
          onClick={()=>{setIsFail(false)}}
      />
    }
    <Button variant="contained" onClick={onSaveClick} className={classes.saveButton}> Save </Button>
    <MarkAsFinishedModal 
      active={mark_as_finished_modal}
      onAdd={didConfirmAsFinished}
      onClose={e=>setMarkAsFinishedModal(false)}
    />
    </>
  )
}

function SummaryTextDetail({classes, text, value}) {
  return (
    <p className={classes.text_property_name}>
      {text}
      <span className={classes.text_property_value}>
        {value}
      </span>
    </p>
  )
}


export default connect(mapStateToProps)(Summary)