import React from 'react';
import { Tooltip } from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export function dateFromTS(timestamp) {
	let date = new Date(timestamp * 1000);
	return date.toLocaleDateString();
}

export function sapColumnFor(order) {
    switch (order.sap_status) {
        case 0:
            if (order.finished) {
                return (
                    <Tooltip title="Finished by customer">
                        <CheckCircleOutlineIcon style={{color: "#4caf50", marginLeft: "0.4em"}} />
                    </Tooltip>
                )
            } else {
                return (
                    <Tooltip title={"open"} aria-label="add">
                    <BlurCircularIcon style={{color: "#e0d850", marginLeft: "0.4em"}} />
                </Tooltip>
                );
            }
        case 1:
            return (
                <Tooltip title={"In SAP"} aria-label="add">
                    <OpenInBrowserIcon style={{color: "#5995c9", marginLeft: "0.4em"}} />
                </Tooltip>
            );

        case 2:
            return (
                <Tooltip title={"Sent"} aria-label="add">
                    <OpenInNewIcon style={{color: "#d0cfcf", marginLeft: "0.4em"}} />
                </Tooltip>
            );
        case 99:
            return(
                <Tooltip title={"Error"} aria-label="add">
                    <WarningIcon style={{color: "#f84949", marginLeft: "0.4em"}} />
                </Tooltip>
                );
        default:
            return(
                <Tooltip title={"Unknown"} aria-label="add">
                    <WarningIcon style={{color: "#fdf018", marginLeft: "0.4em"}} />
                </Tooltip>
            );
        }
}