import { makeStyles } from '@material-ui/core/styles';

export const styles =  makeStyles(theme => ({
  text_h1: {
    fontSize:"2.5em",
    fontWeight:"600",
    color: theme.wls.texts.title,
    margin:"0 0 0.5em",
  },
  text_base: {
    color: theme.wls.texts.subtitle,
    fontSize:"1.3em",
  },

  button:{
    width:"100%",
    fontSize:"1.5em",
    margin:"1em 0 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"1em",
    },
  },
  buttonExcel:{
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[200],
    '&:hover':{
      color: theme.palette.common.white,
      backgroundColor: "#287233",
      opacity: "0.8",
      boxShadow: "none",
    },
    '&:not(:hover) svg': {
      color: "#287233",
    }
  },
  buttonPDF:{
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[50],
    '&:hover':{
      color: theme.palette.common.white,
      backgroundColor: theme.wls.red,
      opacity: "0.8",
      boxShadow: "none",
    },
    '&:not(:hover) svg': {
      color: theme.wls.red,
    }

  },

  saveButton:{
    width:"100%",
    fontSize:"1.5em",
    margin:"1em 0 0",
    backgroundColor: theme.palette.grey[300],
    color: "black",
    [theme.breakpoints.down('md')]: {
      marginTop:"1em",
    },
  },

  finishedButton:{
    width:"100%",
    fontSize:"1.5em",
    margin:"1em 0 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"1em",
    },
  },
  search_form:{
    margin:"1em 0",
    width:"100%"
  },
  text_property_name: {
    fontSize:"1.3em",
    borderBottom:"1px solid #eaeff1",
    paddingBottom:".5em",
    color: theme.wls.texts.title,
  },
  text_property_value: {
    color: theme.wls.texts.subtitle,
    fontWeight:"300",
  },
  onlySelectedControl:{
    marginBottom:"1em",
    marginLeft:"0",
    marginRight:"0",
    paddingBottom:".5em",
    borderBottom:`1px solid ${theme.palette.grey[300]}`,
    width:"100%",
    color: theme.wls.texts.checkbox,
  },
  finishedCheckbox:{
    marginTop:"1em",
    marginLeft:"0",
    marginRight:"0",
    paddingTop:".5em",
    borderBottom:`1px solid ${theme.palette.grey[300]}`,
    borderTop:`1px solid ${theme.palette.grey[300]}`,
    width:"100%",
    color: theme.wls.texts.checkbox,
  },
  drop_summary: {
    marginLeft: "1em",
  },
  downloadButtonBox: {
    display: "flex",
  }
}));
