import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    minHeight:"calc(90vh - 2em)",
    padding:"0 !important",
    backgroundColor: "transparent !important",
  },
  main_content: {
    width:"100%",
    minHeight:"calc(90vh - 2em)",
    padding:"0",
    fontFamily:theme.typography.fontFamily,
    fontSize:"10px",
  },
  filterBox: {
    width:"100%",
    padding:"2em",
    display:"flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom:"2em",
    backgroundColor: theme.wls.mainBg.primary,
    [theme.breakpoints.down('sm')]: {
      width:"calc(100% - 1em)",
      padding:"2em",
      position:"relative",
      marginRight:"0",
    },
  },
  searchFieldBox:{
  },
  right_column: {
    width:"100%",
    display:"flex",
    flexWrap:"wrap",
    backgroundColor: theme.wls.mainBg.primary,
    padding:"2em",
    [theme.breakpoints.down('sm')]:{
      width:"calc(100% - 1em)",
      padding:"2em",
      marginLeft:"0",
    },
  },
  text_h1: {
    fontSize:"18px",
    fontWeight:"600",
    color: theme.wls.texts.title,
  },
  search_form:{
    margin: "0 2em",
  },
  table_action_icon:{
    color:theme.palette.primary.main,
    margin: "0 0.5em",
    '&:hover':{
      color:theme.palette.primary.main,
    }
  },
  filter_current_catalog: {
    marginRight: "10px",
  },
  filter_container: {
    padding: "0 0 0 1.5em",
    display: "flex",
    alignItems: "center",
  },
  formControlCatalogs: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  modalFilterBox:{
    display: "flex",
  },
  mdSelect: {
    minWidth: "80px",
  },
  form_container:{
    display:"flex",
    justifyContent:"center",
    alignItems:"stretch",
    boxShadow:"none",
    maxHeight: '80vh',
  },
}));