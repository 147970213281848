import React from "react";
import { createStore, combineReducers } from 'redux';
import {updateOrder} from 'library/order/update';

export const WLSContext = React.createContext({
  user: {},
});

function snackBarReducer(state={show: false, message: "", severity: "info"}, action) {
  switch (action.type) {
    case "SHOW_MESSAGE":
      return {show: true, ...action.payload}
    case "ALERT_CLOSE":
      return {show: false, message: "", severity: "success"}
    default:
      return state
  }
}

function orderListReducer(state={loaded: false}, action) {
  switch (action.type) {
    case "UNBLOCK_ORDER_SHOW":
      return {...state, unblock_order_show: true, element: null}
    case 'UNBLOCK_ORDER_DISMISS':
      document.getElementsByClassName('OrderListActiveButton')[0].classList.toggle('OrderListActiveButton');
      return {...state, unblock_order_show: false, element: null}
    case 'SET_LOADED':
      return {loaded: true}
    case 'RELOAD_TABLE':
      return {loaded: false}
    default:
      return state
  }
}

function consolidateOrderForSend(order, feedback) {
  let lines = {};
  for (var generic in order.lines) {
    for (var sku in order.lines[generic]) {
      lines[sku] = order.lines[generic][sku];
    }
  }
  updateOrder({...order, lines: lines}).then(
  // All good
  e=>{
    if (feedback === true) {
      store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": "Order saved", severity: "success"}})
    }
    if (e.status==="error") {
      store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": e.error, severity: "error"}})
    }
  },
  // On fail
  () => {
    store.dispatch({type: "SHOW_MESSAGE", "payload": {"message": "Could not save the order, please try again later", severity: "error"}})
  }
  );
}

/* Update the quantities on a product */
function updateProductInState(state, generic, sku, quantity) {
  for (var i = state.garments.length - 1; i >= 0; i--) {
    let this_garment = state.garments[i];
    if (this_garment.generic === generic) {
      for (var j = this_garment.skus.length - 1; j >= 0; j--) {
        let this_sku = this_garment.skus[j];
        if (this_sku.sku === sku) {
          state.garments[i].skus[j].quantity = quantity;
          if (quantity === 0) {
            delete state.garments[i].skus[j].quantity;
          }
          return state;
        }
      }
    }
  }
}

function linesheetReducer(state={loaded: false}, action) {
  // eslint-disable-next-line
  let new_state = {};
  switch (action.type) {
    case 'SET_SERVICE_DATE':
      let new_sd_order = {...state, service_date: action.date};
      consolidateOrderForSend(new_sd_order);
      return new_sd_order;
    case 'SET_ORDER':
      return {...state, ...action, filters: {}, lightbox: {open: false, pictures: []}}
    case 'SET_QUANTITY':
      let new_lines = {...state.lines};
      //console.log("[REDUX][SET_QUANTITY]", action, new_lines);
      if (new_lines[action.garment] === undefined) {
        new_lines[action.garment] = {};
      }
      new_lines[action.garment][action.sku] = action.quantity;
      //console.log(new_lines);
      let new_state = updateProductInState(state, action.garment, action.sku, action.quantity);
      let generic_color = action.sku.substr(0,9);
      let updated_order = {...new_state, lines: new_lines, wanted: state.wanted.filter(x=>x!==generic_color)};
      consolidateOrderForSend(updated_order);
      //console.log(updated_order);
      return updated_order;
    case 'SEND_ORDER':
      consolidateOrderForSend(state, true);
      return state;
    case 'REMOVE_SKU':
      new_lines = {...state.lines};
      //console.log("Deleting quantity for SKU", action.sku);
      if (! (action.garment in new_lines)) {
        return state;
      }
      delete new_lines[action.garment][action.sku];
      if (Object.keys(new_lines[action.garment]).length === 0) {
        delete new_lines[action.garment];
      }
      new_state = updateProductInState(state, action.garment, action.sku, 0);
      let new_order_remove = {...new_state, lines: new_lines};
      consolidateOrderForSend(new_order_remove);
      return new_order_remove;
    case 'SET_FILTER':
      return {...state, filters: action.filter};
    case 'SET_LIGHTBOX':
      return {...state, lightbox: action.lightbox};
    case 'SET_FINISHED':
      //console.log("[REDUX][SET_FINISHED]", action);
      let order_finished = {...state, customer_finished: action.customer_finished};
      consolidateOrderForSend(order_finished)
      return order_finished;
    case 'TOGGLE_WANTED':
      let new_wanted = [...state.wanted]
      if (state.wanted.includes(action.item)) {
        new_wanted = new_wanted.filter(x => x !== action.item);
      } else {
        new_wanted.push(action.item);
      }
      new_state = {...state, wanted: new_wanted};
      consolidateOrderForSend(new_state);
      return new_state;
    default:
      return state
  }
}

const rootReducer = combineReducers({order_menu: orderListReducer, alert_bar: snackBarReducer, wls: linesheetReducer});
export const store = createStore(rootReducer);