import { makeStyles } from '@material-ui/core/styles';

export const styles =  makeStyles(theme => ({
  main: {
    width:"calc(100% - 2em)",
    padding:"1em",
    backgroundColor:theme.wls.mainBg.secondary,
  },
  main_content: {
    width:"calc(100% - 2em)",
    padding:"1em",
    backgroundColor:theme.wls.mainBg.primary,
    fontFamily:theme.typography.fontFamily,
    fontSize:"10px",
    display:"flex",
    flexWrap:"wrap",
  },
  left_column: {
    width:"calc(20% - 5em)",
    overflowY: "auto",
    marginRight:"1em",
    padding:"2em",
    position:"fixed",
    height:"calc(100vh - 8em)",
    [theme.breakpoints.down('sm')]: {
      width:"calc(100% - 1em)",
      padding:"2em",
      position:"relative",
      marginRight:"0",
    },
    [theme.breakpoints.up('xl')]: {
      width:"calc(15% - 5em)",
    },
  },
  right_column: {
    width:"calc(80% - 5em)",
    marginLeft:"calc(25% + 2em)",
    display:"flex",
    flexWrap:"wrap",
    border:"1px solid #FBFBFB",
    [theme.breakpoints.down('sm')]:{
      width:"calc(100% - 1em)",
      padding:"2em",
      marginLeft:"0",
    },
    [theme.breakpoints.up('xl')]: {
      width:"calc(85% - 3px)",
      marginLeft:"calc(15% + 2em)",
    },
  },
}));
