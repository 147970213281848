import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1C83DC',
    },
    /*
    secondary:{
      main: '#DC831C',
    },
    */
    details:"#1C83DC",
    backgroundDetails:"rgba(15,76,129, .75)",
    //colorText:"#B4B4B4",
    backgroundTable:"#F5F6FA",
    colorTable:"#737694",
  },
  typography: {
    fontFamily:"MaisonNeue",
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  wls: {
    red: "#D73737",
    green:"#33dc7b",
    blue: "#1C83DC",
    navigation: {
      backgroundColor: "#000000",
    },
    mainBg: {
      primary: "#ffffff",
      secondary: "#FBFBFB",
      contrast: "#E8E8E8",
    },
    texts: {
      title: "#4D4F5C",
      subtitle: "#B4B4B4",
      checkbox: "#4D4F5C",
    }
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        borderRadius:"0",
        color:theme.palette.primary.contrastText,
        backgroundColor:theme.palette.grey[900],
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover':{
          backgroundColor:theme.palette.primary.main,
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

export default theme;
