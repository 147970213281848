const available_sizes = ["xxs", "xs", "s", "sm", "m", "l", "lxl","xl", "xxl", "xxxl"];
export function getColorsAndSizesFromSku(e){
  const sku_sizes = e.map(x => x.size.toLowerCase());

  // Sizes
  let sizes = available_sizes
	  .map(x => sku_sizes.includes(x) ? x.toUpperCase() : null)
	  .filter(x => x);
  if (! sizes.length) {
  	sizes = [...new Set(sku_sizes)].map(x =>  x.toUpperCase());
	sizes.sort((x,y) => (x - y));
  }

  // Colors
  const colors_dedup = Object.assign({}, ...e.map((x) => ({[x.color]: x.color_name})));
  let colors = Object.entries(colors_dedup)
  	.map(([k,v], i) => ({"number": k, "name": v}));
  colors.sort((x,y) => (parseInt(x.number) - parseInt(x.number)));

  return(
    {sizes:sizes, colors:colors}
  );
}

// From a list of skus, return the one with that ending
function getSKU(color_size, skus) {
	for (let i=0; i < skus.length; i++) {
		if (skus[i].sku.endsWith(color_size))
			return skus[i];
	}
}

// Return quantitites map
export function getSKUsMap(e) {
	let {sizes, colors} = getColorsAndSizesFromSku(e);
	let return_array = [];

	for (let i = 0; i < colors.length; i++) {
		let color_array = [];
		for (let j = 0; j < sizes.length; j++) {
			let color_size = colors[i].number + sizes[j];
			let sku = getSKU(color_size, e);
			color_array.push(sku);
		}
		return_array.push(color_array);
	}
	return return_array;
}

export function OrderLinesMap(lines, to_idx=6) {
	/*
	Input: 
	{SKU: quantity}
	Output: a dictionary from a sku list in format
	{"generic": {"SKU": quantity}}
	*/
	return Object.keys(lines).reduce((filtered,key)=>{
		if (filtered[key.substr(0,to_idx)]) {
			filtered[key.substr(0,to_idx)][key] = lines[key];
		} else {
			filtered[key.substr(0,to_idx)] = {[key]: lines[key]}
		}
		return filtered
	},{});
}

export function notnull(data) {
	return data !== null && data !== undefined;
}