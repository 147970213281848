import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TableContainer, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  table_container:{
    maxHeight: '80vh',
  },
  table_head:{
    '& tr':{
      backgroundColor:theme.palette.backgroundTable,
    },
    '& th':{
      textTransform:"uppercase",
      fontSize:"12px",
      color: theme.palette.colorTable,
      fontWeight:"600",
      border:"0",
    },
  },
  table_body:{
    padding: "0 6px 6px 6px",
    '& th':{
      paddingLeft: "12px",
      fontSize:"12px",
      color: theme.palette.colorTable,
    },
    '& td':{
	    fontSize:"12px",
	    color: theme.palette.colorTable,
	  },
  },
  table_body_cell: {
    padding: "6px",
  },
}));

export default function WLSTable({rows, columns, shouldShowRow, classes, hidden, order_by = 'id'}) {
	const [orderBy, setOrderBy] = React.useState(order_by);
	const [order, setOrder] = React.useState('asc');
  const classes_default = useStyles();

	const handleRequestSort = (event, property) => {
	    const isAsc = orderBy === property && order === 'asc';
	    setOrder(isAsc ? 'desc' : 'asc');
	    setOrderBy(property);
	};

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
		  const order = comparator(a[0], b[0]);
		  if (order !== 0) return order;
		  return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	}

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

	return (
        <TableContainer component={Paper} hidden={hidden} className={clsx(classes.table_container, classes_default.table_container)}>
		    <Table stickyHeader className={clsx(classes.table, classes_default.table)} size="small">
		        <EnhancedTableHead
	              headCells={columns} 
	              classes={classes}
	              order={order}
	              orderBy={orderBy}
	              onRequestSort={handleRequestSort}
	            />
		        <TableBody className={clsx(classes_default.table_body, classes.table_body)}>
		          {stableSort(rows, getComparator(order, orderBy))
                .map((customer) =>
		          	shouldShowRow(customer) && (
		            <TableRow key={customer.id}>
		            {columns.map((c,idx) => (
		            	<TableCell 
		            		component={idx===0 ? "th" : "td"}
		            		padding={c.disablePadding ? "none" : "checkbox"}
		            		align={c.align ? c.align : "center"}
		            		size={c.size ? c.size : "medium"}
		            		scope="row"
	              		className={clsx(classes_default.table_body_cell, classes.table_body_cell)}
		            		key={`${customer.id}-${idx}`}
		            	>
		            	{c.rowContent ? c.rowContent(customer) : customer[c.id]}
		            	</TableCell>
		            ))}
		            </TableRow>
		          ))}
		        </TableBody>
	    	</Table>
		</TableContainer>
	);
}