import React from 'react';
import { uploadBulk } from 'library/user/create';
import { FormGroup, TextField, Button } from '@material-ui/core';
import { useStyles } from './Style';

export default function CustomerBulkUpload() {
  const classes = useStyles();
  const [excel_file, setExcelFile] = React.useState();
  const [response_error_content, setErrorContent] = React.useState([]);

	function handleSubmit(event) {
		event.preventDefault();
    uploadBulk(excel_file).then(response => {
      if (response.status === "success") {
        alert("Awesome! Catalog was updated");
      } else if (response.status === "error") {
        setErrorContent(response.error);
      } else {
        alert("Problem updating the users list, try again later!");
      }
    })
	}
	function handleFileAdded(event) {
		event.preventDefault();
		if (event.target.files.length === 0) {return;}
		let file = event.target.files[0];
    setExcelFile(file);
	}

	return (
    <div>
      <h1 className={classes.text_h1}>Create users in bulk</h1>
      <div className={classes.form_container}>
        <form className={classes.form} onSubmit={handleSubmit}>
        <FormGroup>
          <TextField className={[classes.form_text_field].join(" ")} label="Load excel" type="file" onChange={handleFileAdded} InputLabelProps={{shrink: true}} inputProps={{"accept": ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}} />
          <Button className={classes.button} type="submit" onClick={handleSubmit}>
            Update!
          </Button>
        </FormGroup>
        </form>
      </div>
      {response_error_content.map(error_msg => (
        <p>{error_msg}</p>
      ))}
    </div>
  );
}
