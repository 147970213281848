import React from 'react';
import {
  Grid
} from '@material-ui/core';
import UserProfile from './UserProfile';
import AgentUsers from './AgentUsers';
import Agents from './Agents';
import UserOrders from './UserOrders';
import { useParams } from 'react-router-dom';
import { getUser } from 'library/user/get';

export default function UserDetail() {
	const [user, setUser] = React.useState();
	const [reload, setReload] = React.useState();
	const params = useParams();

	React.useEffect(() => {
		getUser(params.userid).then(setUser);
	}, [params, reload])

  if (!user) {
    return <></>
  }
	return (
    <Grid container spacing={3} >
      <Grid item lg={4} md={6} xs={12}>
       <UserProfile user={user} onUpdate={setReload} />
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
      <Grid container spacing={3} >
        {!user.roles.includes('admin') &&
        <Grid item md xs={12}>
         <Agents user={user} onUpdate={setReload} />
        </Grid>
    	  }
        <Grid item md xs={12}>
         <UserOrders user={user} onUpdate={setReload} />
        </Grid>
        {user.roles.includes('agent') && 
        <Grid item xs={12} >
         <AgentUsers user={user} />
        </Grid>
    	  }
      </Grid>
      </Grid>
    </Grid>
	)
}