export function createCatalog(fields) {
    const FD  = new FormData();
    for (var key in fields) {
        FD.append(key, fields[key]);
    }
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/catalog/upload',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         body: FD
    	}
    ).then(r=> {
        return r.json()
    });
}