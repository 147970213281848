
export function garmentForSKU(garments, sku) {
  return garments.find( garment => sku.startsWith(garment.generic))
}
export function garmentForGeneric(garments, generic) {
  return garments.find( x => generic === x.generic)
}

export function summaryFor(garments, catalog, lines, wanted) {
  let summary = {count:0, price: 0, currency: catalog.currency, wanted: 0, quantity: 0, drops: {}};
  for (let generic in lines) {
    let quantity = Object.values(lines[generic]).reduce((x,y)=>(x+y), 0);

    let garment = garmentForGeneric(garments, generic);
    if (garment === undefined) {continue;}
    if (summary.drops[garment.drop] === undefined)
      summary.drops[garment.drop] = 0;
    summary.drops[garment.drop] += quantity;
    summary.quantity += quantity;
    summary.price += garment.wh_price * quantity;
    summary.count += 1;
  }
  summary.wanted = wanted ? wanted.length : 0;
  if (Object.keys(summary.drops).length < 2) {
    summary.drops = {};
  }
  return summary
}