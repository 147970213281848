import React,{ useEffect } from 'react';
import { getCatalog } from 'library/catalog/get';
import { useStyles } from './Style';
import TextField from '@material-ui/core/TextField';
import { useParams } from 'react-router-dom';
import {GarmentDetailUnCached} from 'components/linesheet/GarmentDetail';
import {priceslists} from "library/constants";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function CatalogPreview() {
  const classes = useStyles();
  const [filter, setFilter] = React.useState({active: true, text: ""});
  const [catalog, setCatalog] = React.useState({});
  const [pricelist, setPricelist] = React.useState("");
  const [lightbox, setLightbox] = React.useState({open: false, garment: ""});
  const {catalog_id} = useParams();

	useEffect(() => {
    if (catalog_id !== undefined && catalog_id !== null) {
      getCatalog({"catalog_id": catalog_id, "price_list": pricelist}).then(x=>{
        x.garments.sort((a,b) => a.number - b.number);
        setCatalog(x);
      });
    }
	},[catalog_id, pricelist]);

  const passes_filter = ((garment) => {
    if (filter.text === "" || filter.text === undefined || filter.text === null) {
      return true;
    }
    var show = false;
    var name_includes_text = (garment.name !== null) && garment.name.includes(filter.text);
    if (name_includes_text) {
      return true;
    }
    var generic_includes_text = garment.generic.includes(filter.text);
    if (generic_includes_text) {
      return true;
    }
    return show;
  });


  function didChangePL(event) {
    setPricelist(event.target.value); // Catalog ID
  }

  function dispatch(request) {
    if (request.type === "SET_LIGHTBOX") {
      setLightbox(request.lightbox);
    }
  }
  const onCloseLightbox = () => {
    setLightbox({open: false, pictures: []});
  }

	return (
    <div>
      <h1 className={classes.text_h1}>Catalog {catalog.name}</h1>
      <div className={classes.filter_container}>
        <TextField 
          label="Search"
          className={classes.padding_text_field}
          onChange={e=>{setFilter({...filter, text: e.target.value})}}
        />
        <FormControl className={classes.formControl}>
          <InputLabel >Pricelist</InputLabel>
          <Select value={pricelist} onChange={didChangePL}>
            <MenuItem key={""} value={""}>None</MenuItem>
            {priceslists.map( pl => (
                <MenuItem key={pl} value={pl}>{pl}</MenuItem>
              ))}
          </Select>
        </FormControl>

      </div>
      <div className={classes.catalog_preview_container}>
      {catalog.garments && catalog.garments.filter(passes_filter).map(x => (
        <GarmentDetailUnCached key={x.generic} product={x} wanted={[]} dispatch={dispatch}/>
      ))}
      </div>
      {
        lightbox.open ? 
        <Lightbox
          open={lightbox.open}
          close={onCloseLightbox}
          carousel={ {finite: true, preload: lightbox.pictures.length}}
          slides={ lightbox.pictures }
          plugins={[Thumbnails]}
        /> : <></>
      }
    </div>
  );
}