
export const user_roles = [
	{id:'agent', formDisplayText: 'Is an agent'},
	{id:'wls', formDisplayText: 'Can use WLS'},
	{id:'app', formDisplayText: 'Can use the App'},
	];


export const priceslists = ["0151","0352","0554","0655", "0751", "0358"];
export const warehouses = ["0001", "0003"];

export const season_regex = /^(AW|SS)[0-9]{2}$/;