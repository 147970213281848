
import { makeStyles } from '@material-ui/core';

const drawerWidth = 256;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  main: {
    flex: 1,
    padding: "2em",
    backgroundColor: theme.wls.mainBg.secondary,
    '& > div':{
      backgroundColor: theme.wls.mainBg.primary,
      padding:"2em",
      minHeight:"100%",
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer_custom:{
      backgroundColor: theme.wls.navigation.backgroundColor,
  },
  item: {
    paddingTop: ".5em",
    paddingBottom: ".5em",
    paddingLeft: "12px",
    color: theme.palette.primary.light,
  },
  itemLogo:{
    '&:hover,&:focus': {
      backgroundColor: theme.wls.navigation.backgroundColor,
      borderLeft:"none",
      transition:"none",
    },
  },
  itemCategory: {
    backgroundColor: theme.wls.navigation.backgroundColor,
    paddingTop: ".5em",
    paddingBottom: ".5em",
    textDecoration: "none",
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.details,
    "& .MuiListItemText-primary": {
      color: theme.palette.primary.light,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.details,
    },
    "& > div":{
      backgroundColor: theme.palette.backgroundDetails,
      borderLeft:"4px solid #1C83DC",
      transition:".3s all ease",
    },
  },
  itemPrimary: {
    fontSize: 16,
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
    color:theme.palette.primary.light,
  },
  list: {
  },
  collapse: {
    backgroundColor: theme.wls.navigation.backgroundColor,
    "& .MuiListItem-button":{
      paddingLeft: "36px",
      borderLeft:`4px solid ${theme.wls.navigation.backgroundColor}`,
      '&:hover,&:focus': {
        backgroundColor: theme.palette.backgroundDetails,
        borderLeft:"4px solid #1C83DC",
        transition:".3s all ease",
      },
    }
  },
  logo: {
    fill:theme.palette.primary.light,
    width:"100%",
    padding:"1em",
  },
}));


export const cardStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 32px 16px",
  },
  headerBox: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionButton: {
    textDecoration: "underline",
    '&:hover': {
      textDecoration: "none",
      background: "none",
    },
    padding: 0,
  },
  // Table
  headerTitle: {
    fontSize: "18px",
    color: theme.palette.text.primary,
  },
  table_head:{
    '& tr':{
    backgroundColor:theme.palette.backgroundTable,
    },
      '& th':{
      textTransform:"uppercase",
      fontSize:"12px",
      color: theme.palette.colorTable,
      fontWeight:"600",
      border:"0",
    },
  },

  table_head_cell: {
    padding: "6px",
    '&:first-of-type': {
      paddingLeft: "12px"
    },
    '&:last-child': {
      paddingRight: "12px"
    }
  },
  table_body_cell: {
    padding: "6px",
    margin: "0px",
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  table_body: {
    padding: "6px",
    '& th': {
      paddingLeft: "12px"
    },
  }

}));
