import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';
import {notnull} from 'library/wls_helper';

const styles = makeStyles((theme) => ({
  form: {
  },
}));

export default function SelectOptions({options = [], title, onChange, value, autoDefault = true}) {
  const classes = styles();
  const [current, setCurrent] = useState("");
  useEffect(()=>{
    if (notnull(value)) {
      return setCurrent(value);
    }
    if (current === "" && options.length > 0 && autoDefault) {
      return setCurrent(options[0].id);
    }
  // eslint-disable-next-line
  }, [options, value]);
  const didChange = (e) => {
    let new_value = e.target.value;
    setCurrent(new_value);
    onChange(new_value);
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="simple-select-label">{title}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={current}
        onChange={didChange}
      >
      {options.map((option, i)=>(
          <MenuItem key={i} value={option.id}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const SalesOrgSelector = (props) => {
  const dictForSO = o => (
    {
      id: `${o.sales_organization}_${o.channel}`,
      name: `${o.sales_organization}/${o.channel}`
    }
  );
  let new_options = props.options.map(dictForSO);
  return <SelectOptions {...props} options={new_options} />
}

export const AddressSelector = (props) => {
  const dictForSO = o => (
    {
      id: o.sap_id,
      name: `${o.name1} (${o.address})`
    }
  );
  let new_options = props.options.map(dictForSO);
  return <SelectOptions {...props} options={new_options} />
}