import React from 'react';
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import './App.css';
import Login from './views/login';
import {AdminInterface} from 'layout/AdminLayout/index.js';
import LinesheetLayout from 'layout/LinesheetLayout';
import { CatalogUploader, CatalogReUploader, CatalogEnabler, CatalogList, CatalogPreview, CatalogImagesUploader, LinkGenerator, OrderList } from 'views/admin';
import { CustomerCreator, CustomerList, CustomerCatalogs, CatalogBatchUpdate, UserDetail, CustomerBulkUpload} from 'views/admin';
import { NewOrder } from 'views/admin';
import { ManagementView } from 'views/admin';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { current as getCurrentUser } from "library/user/get";
import { WLSContext, store } from 'wls_context';
import { Provider } from 'react-redux';
import AlertSnackBar from 'components/AlertSnackBar';

function App() {
  const [ctx, setCtx] = React.useState({});
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname !== '/login') {
      if (location.pathname.includes('/linesheet/')) {
        return;
      }
      getCurrentUser().then(e=> {
        setCtx(c=> ({...c, 'user': e}));
      })
    }
  },[location]);

  return (
  <ThemeProvider theme={theme}>
  <Provider store={store}>
  <WLSContext.Provider value={{ctx}}>
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Navigate replace to="/login" />} />
        <Route path="admin" element={<AdminInterface />} >
            <Route path="catalog">
              <Route path="upload" element={<CatalogUploader />} />
              <Route path="reupload" element={<CatalogReUploader />} />
              <Route path="enable" element={<CatalogEnabler />} />
              <Route path="batch" element={<CatalogBatchUpdate />} />
              <Route path="images" element={<CatalogImagesUploader />} />
              <Route exact path="list" element={<CatalogList />} />
              <Route path="list/:catalog_id" element={<CatalogPreview />} />
            </Route>
            <Route path="order/for/:userid" element={<NewOrder />} />
            <Route path="orders">
              <Route path="new_link" element={<LinkGenerator />} />
              <Route path="new" element={<NewOrder />} />
              <Route path="list" element={<OrderList />} />
            </Route>
            <Route path="user/:userid" element={<UserDetail />} />
            <Route path="users">
              <Route path="list" element={<CustomerList />} />
              <Route path="add" element={<CustomerCreator />} />
              <Route path="bulkadd" element={<CustomerBulkUpload />} />
              <Route path="catalogs" element={<CustomerCatalogs />} />
            </Route>
            <Route path="ed" element={<ManagementView />} />
        </Route>
        <Route path="/linesheet/:id" element={<LinesheetLayout />} />
      </Routes>
    </div>
    <AlertSnackBar />
    </WLSContext.Provider>
    </Provider>
    </ThemeProvider>
  );
}

export default App;
