import React from 'react';
import {
  Avatar,
  Box,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import { user_roles } from 'library/constants';
import { updateUserRoles, updateUserPassword, deleteUser, updateUserProfile } from 'library/user/edit';
import ChangePasswordModal from 'components/ChangePasswordModal';
import ChangeProfileUserModal from "components/modals/users/ChangeProfileUserModal";
import DeleteUserModal from "components/modals/users/DeleteUserModal";
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import Snackbar from '@material-ui/core/Snackbar';
import {WLSContext} from 'wls_context.js';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:last-child': {
      paddingBottom: 16
    }
  },
  userInfoBox:{
    padding: "16px 0",
    borderBottom: `1px solid ${theme.wls.mainBg.contrast}`
  },
  avatar: {
    height: 65,
    width: 65,
    marginRight: "16px",
  },
  userNameText: {
    fontSize: "20px",
    lineHeight: "26px",
    margin: 0,
  },
  userDetailMailText: {
    fontSize: "14px",
    margin: 0,
    color: theme.palette.text.secondary,
  },
  rolesContainer: {
    padding: "16px 0",
    borderBottom: `1px solid ${theme.wls.mainBg.contrast}`
  },
  buttonsContainer: {
    padding: "16px 0 0px 0",
  },
  actionButton: {
    width: "100%",
    marginTop: "0.5em",
  },
  dangerButton: {
    width: "100%",
    background: "tomato",
  },

  roleCheckbox: {
    width: "100%",
  },
  rolesLabel: {
    fontSize: "18px",
    color: theme.palette.text.primary,
    margin: "8px 0",
  }
}));

export default function UserProfile({user, onUpdate, ...rest}) {
  const classes = useStyles();
  const [userFields, setUserFields] = React.useState(user);
  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false);
  const [showModifyDataModal, setShowModifyDataModal] = React.useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false);
  const [showNotificationModal, setShowNotificationModal] = React.useState({"show": false});
  const {ctx} = React.useContext(WLSContext);

  const didChangeRole = (e) => {
    let name = e.target.name;
    let roles = [];
    if (e.target.checked) {
      roles = [...userFields.roles, name];
    } else {
      roles = userFields.roles.filter(c => c !== name)
    }
    setUserFields({...userFields, "roles": roles});
    updateUserRoles(user.id, roles).then(x=>{
      if (x.status === "success") {
        onUpdate(roles);
      } else {
        alert(x.error);
      }
    });
  }

  const didChangePassword = (new_password) => {
    setShowChangePasswordModal(false);
    updateUserPassword(user.id, new_password).then(x=>{
      if (x.status === "success") {
        setShowNotificationModal({"show": true, message: "Password changed"});
      } else {
        setShowNotificationModal({"show": true, message: `Error :( ${x.error}`});
      }
    })
  }

  const didChangeProfile = (user) => {
    setShowModifyDataModal(false);
    updateUserProfile(user).then(x=>{
      if (x.status === "success") {
        setShowNotificationModal({"show": true, message: "Modified user"});
      } else {
        setShowNotificationModal({"show": true, message: `Error :( ${x.error}`});
      }
    })
  }

  const didDeleteUser = (toDelete) => {
    setShowDeleteUserModal(false);
    deleteUser(toDelete).then(x=>{
      if (x.status === "success") {
        setShowNotificationModal({"show": true, message: "User deleted"});
      } else {
        setShowNotificationModal({"show": true, message: `Error :( ${x.error}`});
      }
    })
  }
  if (ctx.user === undefined) {
    return (<></>);
  }
  const is_agent = ctx.user.roles.includes('agent');
  const is_admin = ctx.user.roles.includes('admin');
  const is_self = ctx.user.id === user.id;

  return (
    <Paper elevation={0} >
    <Card >
      <CardContent className={classes.root}>
        <Box alignItems="center" display="flex" className={classes.userInfoBox}>
          <Avatar className={classes.avatar}>{user.name ? user.name.substr(0,1) : '?'}</Avatar>
          <div className={classes.userData}>
            <p className={classes.userNameText}>
              {user.name}
            </p>
            <p className={classes.userDetailMailText}>
              {user.email}
            </p>
            <p className={classes.userDetailMailText}>
              {user.extra_info}
            </p>
            { !user.sap_id ? <></> :
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              Sap ID: {user.sap_id}
            </Typography>
          }
          </div>
        </Box>
        <Box className={classes.rolesContainer}>
          <InputLabel id="catalog-select-label" className={classes.rolesLabel}>Roles</InputLabel>
           {user_roles.map(role =>(
            <FormControlLabel key={role.id} control={
              <PrimaryCheckbox
                checked={userFields.roles ? userFields.roles.includes(role.id) : false}
                name={role.id}
                onChange={didChangeRole}
                color="primary"
                />}
            label={role.formDisplayText} className={classes.roleCheckbox}/>
            ))}
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button className={classes.actionButton} variant="contained" onClick={e=> {setShowChangePasswordModal(true)}}>
            Reset password
          </Button>
          { ( is_admin || (is_agent && !is_self)) && 
          <Button className={classes.actionButton}  variant="contained"  onClick={e=> {setShowModifyDataModal(true)}}>
            Modify data
          </Button>
          }
          { (is_agent || is_admin) && !is_self &&
          <Button className={classes.actionButton}  variant="contained" onClick={e=> {setShowDeleteUserModal(true)}}>
            Delete user
          </Button>
          }
        </Box>

      </CardContent>
    </Card>
    <ChangePasswordModal 
      active={showChangePasswordModal}
      onClose={e=>{setShowChangePasswordModal(false)}}
      onAdd={didChangePassword}
      classes={classes}
    />
    <ChangeProfileUserModal
        active={showModifyDataModal}
        onClose={e=>{setShowModifyDataModal(false)}}
        onChangePassword={() => {setShowModifyDataModal(false); setShowChangePasswordModal(true)}}
        onEdit={didChangeProfile}
        user={user}
        classes={classes}
    />
    <DeleteUserModal
        active={showDeleteUserModal}
        onClose={e=>{setShowDeleteUserModal(false)}}
        onDelete={didDeleteUser}
        user={user}
        classes={classes}
    />

    <Snackbar
      anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
      open={showNotificationModal.show}
      autoHideDuration={1000}
      onClose={e=>{setShowNotificationModal({...showNotificationModal, "show": false})}}
      message={showNotificationModal.message}
      action={
        <Button color="secondary" size="small" onClick={e=>{setShowNotificationModal({...showNotificationModal, "show": false})}}>
          CLOSE
        </Button>
      }
    />
    </Paper>
  );
};
