import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import { deleteOrder } from 'library/order/delete';

const styles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function DeleteOrderModal(props) {
  const { open, order, onClose, setMessage } = props;
  const classes = styles();

  const onDeleteClick = () => {
    deleteOrder(order.id).then(response => {
      if (response.status === "success") {
        setMessage({message: "Order deleted", severity: "success"});
        onClose();
      } else {
        console.log(response);
        alert(response.error);
      }
    });
  }
  if (!open) {
    return <></>
  }

  return (
    <Dialog open={open} keepMounted onClose={onClose} >
        <DialogTitle>Delete order</DialogTitle>
        <DialogContent className={classes.content}>
          Are you sure you want to delete the order for "{order.customer.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onDeleteClick} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}
