export function uploadImages(files) {
    const FD  = new FormData();
    for (var i = files.length - 1; i >= 0; i--) {
        FD.append("images", files[i]);
    };
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/mgmt/upload_images',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         body: FD
    	}
    ).then(r=> {
        return r.json()
    });
}

export function uploadColors(files) {
    const FD  = new FormData();
    for (var i = files.length - 1; i >= 0; i--) {
        FD.append("images", files[i]);
    };
    return fetch(process.env.REACT_APP_WLS_API_URL + '/api/mgmt/upload_colors',
        {method: 'POST',
         mode: 'cors',
         credentials: "include",
         body: FD
        }
    ).then(r=> {
        return r.json()
    });
}