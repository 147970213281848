import React from 'react';
import GarmentDetail from 'components/linesheet/GarmentDetail';
import { connect } from 'react-redux';
import {check_filters} from './filter_functions';

function mapStateToProps(state, ownProps) {
  const { wls, dispatch } = state;
  return { garments: wls.garments, filters: wls.filters, lines: wls.lines, wanted: wls.wanted, dispatch: dispatch }
}

/*
Garment matrix, right side of the WLS public interface
*/
function GarmentMatrix({garments, filters, lines, wanted, dispatch}) {
	const shouldShow = (x) => {
    return check_filters(x, filters, lines, wanted);
  }

	return (
		<>
		{garments && garments.filter(shouldShow).map(x => (
			<GarmentDetail key={x.generic} product={x} wanted={wanted.filter(y => y.startsWith(x.generic))}/>
		))}
		</>
		);
}

export default connect(mapStateToProps)(GarmentMatrix)
