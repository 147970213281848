import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
  table_head:{
    '& tr':{
      backgroundColor:theme.palette.backgroundTable,
    },
    '& th':{
      textTransform:"uppercase",
      fontSize:"12px",
      color: theme.palette.colorTable,
      fontWeight:"600",
      border:"0",
    },
  },
  table_head_cell: {
    padding: "6px",
    '&:first-of-type': {
      paddingLeft: "12px"
    },
    '&:last-child': {
      paddingRight: "12px"
    }
  },
}));

export default function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const classes_default = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={clsx(classes_default.table_head, classes.table_head)}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={clsx(classes_default.table_head_cell, classes.table_head_cell)}
            key={headCell.id}
            align={headCell.align}
            size={headCell.size ? headCell.size : "medium"}
            variant="head"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
          {headCell.hideSort ? 
            (<p>{headCell.label}</p>)
            :
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
