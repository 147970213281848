import React,{ useEffect, Fragment, useContext } from 'react';
import { getCatalogList } from 'library/catalog/get';
import { createUserOrder, createUserOrderFor } from 'library/order/create';
import { FormGroup, TextField, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ErrorModal from 'components/errorModal';
import AlertModal from 'components/alertModal';
import {useStyles} from './Style_create.js';
import { useParams } from 'react-router-dom';
import {SalesOrgSelector, AddressSelector} from 'components/SelectOptions';
import { getUser } from 'library/user/get';
import {notnull} from 'library/wls_helper';
import { WLSContext } from 'wls_context';

export default function NewOrder() {
  const classes = useStyles();
  const [catalogList, setCatalogList] = React.useState([]);
  const [userFields, setUserFields] = React.useState({catalog:"", send_email:true});
  const [errorStatus, setErrorStatus] = React.useState({failed:false, message:"",});
  const [alertStatus, setAlertStatus] = React.useState({show:false, message:"",});
  const [customer, setCustomer] = React.useState({addresses: [], sales_organizations:[]});
  const {ctx} = useContext(WLSContext);
  const params = useParams();
  const user_id = params.userid;

  useEffect(() => {
    getCatalogList().then(setCatalogList);
    if (params.userid) {
      getUser(params.userid, false, true).then(data => {
        let e = removeFRAddresses(data);
        setCustomer(e);
        let default_addr = e.addresses.length === 1 ? e.addresses[0].sap_id : null;
        let default_so = e.sales_organizations.length === 1 ? `${e.sales_organizations[0].sales_organization}_${e.sales_organizations[0].channel}` : null;
        setUserFields({...userFields, ship_to: default_addr, sales_org: default_so})
      });
    } else {
      getUser(ctx.user.id, false, true).then(data=>{
        let e = removeFRAddresses(data);
        setCustomer(e);
        let default_addr = e.addresses.length === 1 ? e.addresses[0].sap_id : null;
        let default_so = e.sales_organizations.length === 1 ? `${e.sales_organizations[0].sales_organization}_${e.sales_organizations[0].channel}` : null;
        setUserFields({...userFields, ship_to: default_addr, sales_org: default_so})
      });
    }
    // eslint-disable-next-line
  },[params]);

  const removeFRAddresses = e => {
    let is_fr = (e.sales_organizations.filter(so => so.channel === "FR").length > 0);
    if (!is_fr) {return e;}

    let new_addrs = e.addresses.filter(addr => addr.sap_id !== e.sap_id);
    if (new_addrs.length === 0)  {return e;}
    e.addresses = new_addrs;
    return e;
  }

  const didCreateOrder = e => {
    if(e.status === "success"){
      setAlertStatus({
        show:true,
        message:<Fragment>Order created: <a href={"/linesheet/" + e.order_id}>view it</a> </Fragment>
      });
    } else {
      setErrorStatus({
        failed:true,
        message:e.error
      });
    }
  }
  function handleSubmit(event) {
    event.preventDefault();
    let payload = {
      title: userFields.title,
      catalog: userFields.catalog,
      address: userFields.ship_to,
    };
    if (notnull(userFields.sales_org))
      [payload.sales_organization, payload.channel] = userFields.sales_org.split('_')
    if (user_id !== undefined) {
      payload.user_id = user_id;
      createUserOrderFor(payload).then(didCreateOrder);
    } else {
      createUserOrder(payload).then(didCreateOrder);
    }
  }

  const handleErrorClose = () => {
    setErrorStatus({failed:false, message:"",});
  };

  const handleAlertClose = () => {
    setAlertStatus({show:false, message:"",});
  };
  const currentCatalog = catalogList.find(x => x.id === userFields.catalog);

  return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>New Order</h1>
      <div className={classes.form_container}>
        <form className={classes.form} onSubmit={handleSubmit}>
        <FormGroup>
          <TextField label="Order name" className={classes.form_text_field} onChange={e => setUserFields({...userFields,title:e.target.value})} />
          <div className={classes.select_container}>
            <InputLabel id="catalog-select-label" className={classes.form_label}>Catalog</InputLabel>
            <Select labelId="catalog-select-label" id="demo-simple-select" value={userFields.catalog} onChange={e => setUserFields({...userFields,catalog:e.target.value})}>
              {catalogList.map(catalog => (
                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name} ({catalog.num_items})</MenuItem>
              ))}
            </Select>
          </div>
          {currentCatalog !== undefined &&
          <div className={classes.cataloginfo}>
            <span>{currentCatalog.brand}</span> <span>{currentCatalog.season}</span>
          </div>
          }
          {customer && 
          <div className={classes.customer_details_content}>
            <p>{customer.name}</p>
            <AddressSelector
              title="Address"
              onChange={e=>{setUserFields({...userFields, ship_to: e})}}
              options={customer.addresses}
              value={userFields.ship_to}
              autoDefault={false}
            />
            <SalesOrgSelector
              title="Sales organization / Channel"
              onChange={e=>{setUserFields({...userFields, sales_org: e})}}
              options={customer.sales_organizations}
              value={userFields.sales_org}
              autoDefault={false}
            />
          </div>
          }
          <Button variant="contained" className={classes.button} type="submit" onClick={handleSubmit}>
            Create
          </Button>
        </FormGroup>
        </form>
      </div>
      <ErrorModal title="Oops!" body={errorStatus.message} open={errorStatus.failed} onClose={handleErrorClose}/>
      <AlertModal title="Order created" body={alertStatus.message} open={alertStatus.show} onClose={handleAlertClose}/>
    </div>
  );
}
