import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import {useStyles} from './Style.js';
import Grid from '@material-ui/core/Grid';
import UploadImagesModal from 'components/modals/management/UploadImages';
import UploadColorsModal from 'components/modals/management/UploadColors';

export default function CatalogImagesUploader() {
  const classes = useStyles();
  const [activeModal, setActiveModal] = useState("");

  return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>Images upload</h1>
      <Grid container className={classes.container}>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("image_upload")}}>
              Images upload
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={2}  className={classes.grid_item}>
          <div >
            <Button variant="contained" className={classes.button} type="submit" onClick={()=>{setActiveModal("color_upload")}}>
              Colors upload
            </Button>
          </div>
        </Grid>
      </Grid>
      <UploadImagesModal active={(activeModal === "image_upload")} onClose={()=>{setActiveModal(false)}}/>
      <UploadColorsModal active={(activeModal === "color_upload")} onClose={()=>{setActiveModal(false)}}/>
    </div>
  );
}
