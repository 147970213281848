import React, {useContext} from 'react';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {priceslists} from "library/constants";
import {WLSContext} from 'wls_context.js';

export function SelectPricelistModal({active, onDone, onClose, classes}) {

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select a price list</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, choose one from below.
          </DialogContentText>
          <Button onClick={() => onDone(null)}>
            None
          </Button>
          {priceslists.map(pl => (
            <Button key={pl} onClick={() => onDone(pl)}>
              {pl}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export function SelectPricelistModalLink({active, linkBase, onClose, classes, customPriceList}) {
  const {ctx} = useContext(WLSContext);

  let prices = (ctx.user && (ctx.user.roles.includes("admin") ? priceslists : [ctx.user.pricelist])) || [];

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select a price list</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please, choose one from below.
          </DialogContentText>
          <Button href={linkBase}  target="_blank" component="a" >
            None
          </Button>
          {prices.map(pl => (
            <Button target="_blank" component="a" key={pl} href={`${linkBase}?pl=${pl}`}>
              {pl}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
}