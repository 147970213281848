import React from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import LineWeight from "@material-ui/icons/LineWeight";
import MenuBook from '@material-ui/icons/MenuBook';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import { NavLink } from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import People from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import LoopIcon from '@material-ui/icons/Loop';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Logo } from 'views/login/logo';
import {WLSContext} from 'wls_context.js';
import { useStyles } from './Style';

var categories = [
  {
    id: 'Orders',
    icon: <WorkOutlineOutlinedIcon />,
    perm: 'wls',
    children: [
      { id: 'New order', icon: <AddCircleOutlineIcon />, url: '/admin/orders/new' },
      { id: 'List', icon: <ListIcon />, url: '/admin/orders/list' },
    ],
  },
  {
    id: 'Catalogs',
    icon: <MenuBook />,
    perm: 'agent',
    children: [
      { id: 'List', icon: <ListIcon />, url: '/admin/catalog/list', perm: 'agent' },
      { id: 'New catalog', icon: <AddCircleOutlineIcon />, url: '/admin/catalog/upload', perm: 'admin' },
      { id: 'Update', icon: <LoopIcon />, url: '/admin/catalog/reupload', perm: 'admin' },
      { id: 'Visibility', icon: <VisibilityIcon />, url: '/admin/catalog/enable', perm: 'admin' },
      { id: 'Batch update', icon: <LineWeight />, url: '/admin/catalog/batch', perm: 'admin' },
      { id: 'Upload images', icon: <AddAPhotoIcon />, url: '/admin/catalog/images', perm: 'admin' },
      // { id: 'Edit', icon: <CheckBoxIcon />, url: '/admin/catalog/edit' },
    ],
  },
  {
    id: 'Customers',
    icon: <People />,
    perm: 'agent',
    children: [
      { id: 'List', icon: <ListIcon />, url: '/admin/users/list' },
      { id: 'Visibility', icon: <VisibilityIcon />, url: '/admin/users/catalogs' },
      { id: 'Create', icon: <AddCircleOutlineIcon />, url: '/admin/users/add' },
    ],
  },
];

function CollapsibleNavList(props) {
    const [open, setOpen] = React.useState(props.open ? true : false);
    const handleClick = () => {
      setOpen(!open);
  };
  return (
    <List disablePadding className={props.classes.list}>
      <ListItem button onClick={handleClick} className={clsx(props.classes.item, props.classes.itemCategory)}>
      {props.icon ? <ListItemIcon className={props.classes.itemIcon}>{props.icon}</ListItemIcon> : null}
            <ListItemText primary={props.primary} classes={{ primary: props.classes.itemPrimary, }} />
              {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
      <Collapse in={open} timeout="auto" className={props.classes.collapse}>
        {props.children}
      </Collapse>
    </List>
  )
}

export default function Navigator(props) {
  const classes = useStyles();
  const { ...other } = props;
  const {ctx} = React.useContext(WLSContext);
  let perms = ctx.user ? ctx.user.roles : [];

  const categoriesFor = (user_permissions) => {
    if (user_permissions.includes('admin') || user_permissions.includes('agent')) {
      let only_list = [{ id: 'List', icon: <ListIcon />, url: '/admin/orders/list' }];
      categories[0].children = only_list;
    }
    return categories.filter(x=>user_permissions.includes(x.perm) || user_permissions.includes("admin"))
  }

  return (
    <Drawer variant="permanent" {...other} classes={{paper:classes.drawer_custom}}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory, classes.itemLogo)}>
          <Logo className={classes.logo}/>
        </ListItem>
        {categoriesFor(perms).map(({ id, children, perm, icon }) => (
          <CollapsibleNavList key={id} classes={classes} primary={id} icon={icon} open={true} items={children.length}>
            {children.map(({ id: childId, icon, url, perm }) => (
              (perms.includes(perm) || perms.includes("admin") || perm === undefined) && 
              <NavLink key={childId} to={url} className={({ isActive }) => "nav-link" + (isActive ? " " + classes.itemActiveItem : "")} >
              <ListItem key={childId} button className={classes.item} >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText classes={{primary: classes.itemPrimary}} >
                  {childId}
                </ListItemText>
              </ListItem>
              </NavLink>
              ))}

            <Divider className={classes.divider} />
          </CollapsibleNavList>
        ))}
      </List>
    </Drawer>
  );
}

