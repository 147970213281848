import React from 'react';
import {notnull} from 'library/wls_helper';

export default function ProductDetailFooter({classes, wanted, product, onColorClicked, onLoseFocus}) {
  return (
      <div className={classes.product_footer}>
	    <div className={classes.product_sizes}>
	        <p></p>
	        <div className={classes.size_color_matrix}>
	          <span className={[classes.product_color_none, classes.product_size].join(" ")}></span>
	          {product.sizes.map((size,index) => (
	            <span key={index} data-size={size} data-index={index} className={classes.product_size}>{size}</span>
	          ))}
	    	</div>
	    </div>
	    {product.colors.map((color,i) => (
	    	<ColorRow
	    	 key={color.number}
	    	 classes={classes}
	    	 color={color}
         skus={product.skus.filter(sku => sku.color === color.number)}
	    	 sizes={product.sizes}
	    	 color_sku={product.generic + color.number}
	    	 onColorClicked={onColorClicked}
         wanted={wanted.filter(x => x.startsWith(product.generic + color.number))}
	    	 onLoseFocus={onLoseFocus}
	    	/>
	    ))}
    </div>
	);
}

function ColorRow({classes, color, sizes, skus, wanted, color_sku, onColorClicked, onLoseFocus}) {
  const classesForLine = e => {
    let wanted_class = " " + classes.wanted;
    return wanted.includes(color_sku) ? wanted_class : "";
  }
  const classesForSize = e => {
    let wanted_class = " " + classes.wanted_size;
    return wanted.includes(color_sku+e) ? wanted_class : "";
  }
	return (
        <div className={classes.color_row + classesForLine(color.number)  }>
          <p data-color-line={color_sku} className={classes.text_title_details} onClick={onColorClicked}>C/{color.number} <span className={classes.color_row_color_name}>{color.name}</span></p>
          <div className={classes.size_color_matrix}>
            <span className={classes.color_row_image_container} onClick={onColorClicked} data-color-line={color_sku}>
                <img alt="color" className={classes.product_color_img} src={"https://wls.nicethings.es/images/colors/"+color.number+".jpg"}/>
            </span>
            {sizes.map( (size,index) => (
              <SKUInput
                key={index}
                stockErrorClass={classes.input__no_stock}
                withStockClass={classes.input_stock}
                inputClass={classes.color_row_input + classesForSize(size)}
                onLoseFocus={onLoseFocus}
                sku={skus.filter(sku => sku.size === size)[0]}
                classesForSize={classesForSize}
              />
            ))}
          </div>
        </div>
	)
}

function SKUInput({onLoseFocus, sku, currentValue, inputClass, stockErrorClass, withStockClass}) {
  if (sku === undefined) {
    return (
          <input
              disabled ={ true }
              className={inputClass}
          />);
  }
  const has_stock = notnull(sku.stock);
  const has_value = notnull(sku.quantity);
  const stock_error = has_stock && has_value && sku.stock < sku.quantity;
  return (
    <div className={stock_error ? stockErrorClass : withStockClass}>
      <input
          onBlur={onLoseFocus}
          defaultValue={sku.quantity}
          data-sku={sku.sku}
          disabled ={ has_stock && sku.stock === 0 && !has_value }
          className={inputClass}
          name={sku.sku}
      />
      { has_stock &&  <label htmlFor={sku.sku} name ="Stock">  {sku.stock}  </label> }
    </div>
  )
}