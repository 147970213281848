import {apiCall, apiPost} from 'library';

export function createOrder(name, email, seller, catalog, is_wholesale, send_email) {
    return apiCall('/api/order/new', 
        {method: 'POST',
         body: JSON.stringify(
                {
                "customer_name": name,
                "customer_email": email,
                "seller": seller,
                "is_wholesale": is_wholesale,
                "send_email": send_email,
                "catalog": catalog
                }
            )
        }
    );
}

export function createUserOrder({title, catalog, address, sales_organization, channel}) {
    let payload = {
                "title": title,
                "catalog": catalog
                };
    if (address)
        payload.address = address;
    if (sales_organization) {
            payload.sales_organization = sales_organization;
            payload.channel = channel;
    }
    return apiCall('/api/order/new_simple', 
        {method: 'POST',
         body: JSON.stringify(payload)
        }
    );
}

export function createUserOrderFor({title, catalog, user_id, address, sales_organization, channel}) {
    let payload = {
                "customer": user_id,
                "title": title,
                "catalog": catalog
                };
    if (address)
        payload.address = address;
    if (sales_organization) {
            payload.sales_organization = sales_organization;
            payload.channel = channel;
    }
    return apiCall('/api/order/new_simple', 
        {method: 'POST',
         body: JSON.stringify(payload)
        }
    );
}

export async function duplicateOrder({source, customer_id, title, address, sales_organization, channel}) {
    return await apiPost({
        endpoint: '/api/order/duplicate', 
        body: {
            "order": source,
            "title": title,
            "customer": customer_id,
            "address": address,
            "sales_organization": sales_organization,
            "channel": channel
            }
        }
    );
}