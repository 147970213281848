import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {SalesOrgSelector, AddressSelector} from 'components/SelectOptions';
import { updateOrderAddress } from 'library/order/update';
import {notnull} from 'library/wls_helper';
import { getUser } from 'library/user/get';

const styles = makeStyles((theme) => ({
  form: {
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function EditOrderAddressSOModal(props) {
  const { open, order, onClose, setMessage } = props;
  const classes = styles();
  const [data, setData] = useState({name: ""});

  useEffect(()=>{
    if (open) {
      let name = order ? order.name : "";
      getUser(order.customer.id, false, true).then(response_data=> {
        let e = removeFRAddresses(response_data);
        let default_addr = e.addresses.length > 0 ? e.addresses[0].sap_id : null;
        let default_so = e.sales_organizations.length === 1 ? `${e.sales_organizations[0].sales_organization}_${e.sales_organizations[0].channel}` : null;
        setData(d => ({...d, name: name, customer: e, ship_to: default_addr, sales_org: default_so}));
      });
    }
  },[order, open]);

  const onDoneClick = () => {
    let payload = {
      order_id: order.id,
      name: data.name
    }
    if (notnull(data.ship_to))
      payload.address = data.ship_to
    if (notnull(data.sales_org))
      [payload.sales_organization, payload.channel] = data.sales_org.split('_');
    updateOrderAddress(payload).then(response => {
      if (response.status === "success") {
        setMessage({message: "Order updated", severity: "success"});
        onClose();
      } else {
        console.log(response);
        alert(response.error);
      }
    })
  }
  if (!open) {return <></>}

  const removeFRAddresses = e => {
    let is_fr = (e.sales_organizations.filter(so => so.channel === "FR").length > 0);
    if (!is_fr) {return e;}

    let new_addrs = e.addresses.filter(addr => addr.sap_id !== e.sap_id);
    if (new_addrs.length === 0)  {return e;}
    e.addresses = new_addrs;
    return e;
  }

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
        <DialogTitle>Edit order</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField required value={data.name} label="Order name" autoFocus name="name" onChange={e => setData({...data, name: e.target.value})} />
          {data.customer !== undefined && 
            <div className={classes.customer_details_content}>
              <p>{data.customer.name}</p>
              <AddressSelector
                title="Address"
                onChange={e=>{setData({...data, ship_to: e})}}
                options={data.customer.addresses}
              />
              <SalesOrgSelector
                title="Sales organization / Channel"
                onChange={e=>{setData({...data, sales_org: e})}}
                options={data.customer.sales_organizations}
              />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onDoneClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}
