import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const checkboxStyles = (theme) => ({
  root: {
    color: theme.palette.details,
    '&$checked': {
      color: theme.palette.details,
    },
  },
  checked: {},
});
const PrimaryCheckbox = withStyles(checkboxStyles)(Checkbox);

export default PrimaryCheckbox;
