import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper
} from '@material-ui/core';
import { getAgentUsers } from 'library/user/get';
import WLSTable from 'components/WLSTable';
import {cardStyles} from './style';

export default function AgentUsers({user}) {
  const classes = cardStyles();
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    getAgentUsers(user.id).then(setUsers);
  }, [user]);
  
  const columns = [
    { id: 'name', align: "left", disablePadding: true, label: 'Name', rowContent: (c) => (
      <a href={`/admin/user/${c.id}`}>{c.name}</a>
      ) },
    { id: 'email', align: "left", disablePadding: true, label: 'Email' },
  ];

  const shouldShow = customer => {
    return true;
  };

  return (
    <Paper
      elevation={0}
      className={classes.root}
    >
      <Box className={classes.headerBox} display="flex">
        <p className={classes.headerTitle}>Customers</p>
      </Box>
      <WLSTable 
        classes={classes}
        columns={columns}
        rows={users}
        shouldShowRow={shouldShow}
      />
    </Paper>
  );
};

AgentUsers.propTypes = {
  user: PropTypes.object,
};
