import { makeStyles } from '@material-ui/core/styles';

export const styles =  makeStyles(theme => ({
  container: {
    width:"33.33%",
    [theme.breakpoints.between('sm', "md")]: {
      width:"calc(50% - 4em)",
      padding:"2em",
    },
    [theme.breakpoints.down('xs')]: {
      width:"100%",
      margin:"1em 0",
    },
  },
  product_body: {
    width:"calc(100% - 4em)",
    display:"flex",
    flexWrap:"wrap",
    padding:"2em",
    [theme.breakpoints.down('md')]: {
      padding:"0",
      width:"100%",
      marginBottom:"2em",
    },
  },
  product_body_left: {
    width:"45%",
    overflow:"hidden",
  },
  product_body_right: {
      width:"calc(55% - 1em)",
      overflow:"hidden",
      padding:".5em",
      paddingTop:"0",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      [theme.breakpoints.up('xl')]: {
        width:"calc(55% - 4em)",
        padding:"2em",
        paddingTop:"0",
      },
      [theme.breakpoints.up('lg')]: {
        width:"calc(55% - 2em)",
        padding:"1em",
        paddingTop:"0",
      },
  },
  product_img: {
      width:"100%",
      height:"auto",
  },
  text_property_name: {
    color: theme.wls.texts.title,
    fontSize:"12px",
    marginTop:"0",
    borderBottom:"1px solid #FBFBFB",
    marginBottom:".25em",
    paddingBottom:".25em",
    [theme.breakpoints.up('xl')]: {
      marginBottom:".75em",
      paddingBottom:".75em",
    },
  },
  text_property_value: {
    color: theme.wls.texts.subtitle,
    fontWeight:"300",
    fontSize:"12px",
  },
  product_footer: {
    width:"calc(100% - 1em)",
    padding:".5em",
    [theme.breakpoints.up('lg')]: {
      maxWidth:"400px",
    },
    [theme.breakpoints.between('sm',"lg")]: {
      maxWidth:"325px",
    },
  },
  input_stock: {
    display: "flex",
    fontSize: "1.3em",
    flexDirection: "column",
    textAlign: "center",
    color: "white",
    backgroundColor: theme.wls.texts.subtitle,
    boxShadow: "0px 0px 2px " + theme.wls.texts.subtitle,
    marginBottom: '.5em'
  },
  input__no_stock:{
    display: "flex",
    fontSize: "1.3em",
    flexDirection: "column",
    textAlign: "center",
    color: "white",
    border: "solid 1px tomato",
    backgroundColor: "tomato",
    boxShadow: "0px 0px 3px tomato",
    marginBottom: '.5em'
  },
  stock:{
    fontSize: "1.3em",
    alignSelf: "flex-end",
    backgroundColor: theme.wls.texts.subtitle,
    padding:" 0.5em",
    color: "white",
  },
  long_description:{
    color: "#ff0000",
    fontSize:"12px",
    marginTop:"0",
    borderBottom:"1px solid #FBFBFB",
    marginBottom:".25em",
    paddingBottom:".25em",
  },
  product_sizes: {
    width:"100%",
    display:"flex",
    justifyContent:"space-between",
    maxHeight:"1.2em",
    padding:"0 .5em",
  },
  color_row: {
    width:"100%",
    display:"flex",
    justifyContent:"space-between",
    padding:"0 .5em",
    marginTop: ".5em"
  },
  color_row_name: {
    width:"25%",
    display:"flex",
    justifyContent:"space-between",
    fontSize:"10px",
  },
  size_color_matrix: {
    width:"75%",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  product_size:{
    color: theme.wls.texts.title,
    width:"20px",
    textAlign:"center",
  },
  color_row_image_container: {
    border:"1px solid #FBFBFB",
    backgroundColor:theme.palette.backgroundTable,
    width:"18px",
    height:"18px",
    overflow:"hidden",
  },
  product_color_img: {
    objectFit:"cover",
  },
  product_color_none: {
    opacity:"0",
  },
  text_title_details: {
    width:"25%",
    justifyContent:"space-between",
    alignSelf: "center",
    margin:".5em 0",
    fontSize:"9px",
    color: theme.wls.texts.title,
  },
  color_row_color_name:{
    display:"block",
    color: theme.wls.texts.title,
  },
  color_row_input: {
    width:"22px",
    height:"18px",
    border: "1px solid #eaeff1",
    outline: "0",
    textAlign: "center",
  },
  wanted: {
    backgroundColor: theme.palette.backgroundTable,
    padding:"0 .5em",
  },
  wanted_size: {
    backgroundColor: theme.palette.backgroundTable,
    width:"22px",
    height:"18px",
    border: "1px solid #eaeff1",
    outline: "0",
    textAlign: "center",
  }
}));
