import React,{ useEffect } from 'react';
import { getCatalogListAdmin } from 'library/catalog/get';
import {SelectPricelistModalLink} from 'components/selectPricelistModal';
import { useStyles } from './Style';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BrushIcon from '@material-ui/icons/Brush';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Tooltip} from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default function CatalogList() {
  const classes = useStyles();
	const [catalogList, setCatalogList] = React.useState([]);
  const [filter, setFilter] = React.useState({active: true, text: ""});
  const [pricelistAction, setPricelistAction] = React.useState({active: false, catalog: ""});

  useEffect(() => {
    getCatalogListAdmin().then(setCatalogList);
  },[]);

  const passes_filter = ((catalog) => {
    var show = true;
    if (filter.active) {
      show = (catalog.is_current === filter.active);
    }
    if (filter.text !== "") {
      show = catalog.name.includes(filter.text);
    }
    return show;
  });

  const didCancelPricelist = (pricelist) => {
    setPricelistAction({active: false, catalog: null});
  }
  const startGetDesignTXT = (catalog_id) => {
    setPricelistAction({active: true, catalog: catalog_id, action: "design"});
  }
  const startGetStockPDF = (catalog_id) => {
    setPricelistAction({active: true, catalog: catalog_id, action: "stock"});
  }

	return (
    <div>
      <h1 className={classes.text_h1}>Catalogs</h1>
      <div className={classes.filter_container}>
        <ToggleButton value="current_catalog" className={classes.filter_current_catalog} aria-label="centered" onChange={e=>{setFilter({...filter, active: !filter.active})}}>
          <Tooltip title="Current catalogs" aria-label="add">
            { filter.active ? 
            <BookmarkIcon style={{color: "#5995c9"}} />
            :
            <BookmarkBorderIcon style={{color: "#5995c9"}} />
            }
          </Tooltip>
        </ToggleButton>
        <TextField 
          label="Search"
          className={classes.padding_text_field}
          onChange={e=>{setFilter({...filter, text: e.target.value})}}
        />
      </div>
      <div className={classes.catalog_list_cards}>
          {catalogList.map((catalog, index) => ( passes_filter(catalog) ?
            <CatalogListCard 
              details={catalog}
              key={index}
              onGetDesignLS={startGetDesignTXT}
              onGetStockLS={startGetStockPDF}
            />
            : <React.Fragment key={index}></React.Fragment>
          ))}
      </div>
    <SelectPricelistModalLink 
      active={pricelistAction.active && pricelistAction.action === "design"}
      linkBase={process.env.REACT_APP_WLS_API_URL + "/api/catalog/preview/txt/" + pricelistAction.catalog}
      onClose={didCancelPricelist}
      classes={classes}
      />
    <SelectPricelistModalLink 
      active={pricelistAction.active && pricelistAction.action === "stock"}
      linkBase={process.env.REACT_APP_WLS_API_URL + "/api/catalog/stocks/pdf/" + pricelistAction.catalog}
      onClose={didCancelPricelist}
      classes={classes}
      />
    </div>
  );
}


const cardStyles = makeStyles(((theme) => ({
  root: {
    width: 275,
    backgroundColor: theme.wls.mainBg.contrast,
    margin: "5px",
  },
  title: {
    fontSize: 14,
    textTransform: "capitalize",
  },
  pos: {
    marginBottom: 12,
  },
  button:{
    borderRadius:"0",
    margin:"0.2em 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"2em",
    },
  }
})));

function CatalogListCard({details, onGetDesignLS, onGetStockLS}) {
  const classes = cardStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {details.brand}
        </Typography>
        <Typography variant="h5" component="h2">
          {details.name}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {details.season} - {details.catalog_type}
        </Typography>
        <Typography variant="body2" component="p">
          {details.num_items} items
        </Typography>

        <div className={classes.downloadButtonBox}>
          <Link to={`/admin/catalog/list/${details.id}`}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonExcel)}
            startIcon={<VisibilityIcon />}
          >
            Web
          </Button>
          </Link>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonPDF)}
            startIcon={<PictureAsPdfIcon />}
            href={`${process.env.REACT_APP_WLS_API_URL}/api/catalog/preview/pdf/${details.id}`}
          >
            PDF
          </Button>
          {details.catalog_type === 'initial' ?
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonStock)}
            startIcon={<BrushIcon />}
            onClick={() => onGetDesignLS(details.id)}
          >
            Design
          </Button>
          : details.catalog_type === 'repo' &&
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonStock)}
            startIcon={<ListAltIcon />}
            onClick={() => onGetStockLS(details.id)}
          >
            Stock
          </Button>
          }
        </div>
      </CardContent>
    </Card>
    )
}