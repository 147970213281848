import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { doLogout } from "library/user/auth";
import {WLSContext} from 'wls_context.js';
import { useStyles } from './Style';
import ChangePasswordModal from 'components/ChangePasswordModal';
import { updateUserPassword } from 'library/user/edit';
import {
  AppBar,
  Avatar,
  Button,
  Fade,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar
} from '@material-ui/core';

export default function Header({onDrawerToggle}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false);
  const [showNotificationModal, setShowNotificationModal] = React.useState({"show": false});
  const {ctx} = React.useContext(WLSContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const didChangePassword = (new_password) => {
    setShowChangePasswordModal(false);
    updateUserPassword(ctx.user.id, new_password).then(x=>{
      if (x.status === "success") {
        setShowNotificationModal({"show": true, message: "Password changed"});
      } else {
        setShowNotificationModal({"show": true, message: `Error :( ${x.error}`});
      }
    })
  }

  return (
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={handleClick}>
                <Avatar alt="User Avatar">{ctx.user ? ctx.user.name.charAt(0) : "N"}</Avatar>
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                TransitionComponent={Fade}
                className={classes.userMenu}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem className={classes.userMenu} onClick={e=> {setShowChangePasswordModal(true)}}>Change password</MenuItem>
                <MenuItem className={classes.userMenu} onClick={doLogout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
        <ChangePasswordModal 
          active={showChangePasswordModal}
          onClose={e=>{setShowChangePasswordModal(false)}}
          onAdd={didChangePassword}
          classes={classes}
        />

        <Snackbar
          anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
          open={showNotificationModal.show}
          autoHideDuration={1000}
          onClose={e=>{setShowNotificationModal({...showNotificationModal, "show": false})}}
          message={showNotificationModal.message}
          action={
            <Button color="secondary" size="small" onClick={e=>{setShowNotificationModal({...showNotificationModal, "show": false})}}>
              CLOSE
            </Button>
          }
        />
      </AppBar>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
