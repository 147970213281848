import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: theme.wls.mainBg.primary,
    flexWrap: "wrap",
  },
  text_h1:{
    marginTop:"0",
    padding: "1em",
    fontFamily:theme.palette.typography,
    color: theme.wls.texts.title,
    fontSize:"20px",
  },
  form_container:{
    display:"flex",
    justifyContent:"center",
    flexWrap: "wrap",
  },
  form:{
    width:"400px",
  },
  form_text_field:{
    borderBottom:"2px solid black",
    marginBottom:".5em",
    color:theme.palette.primary.dark,
    fontSize:"12px",
  },
  button:{
    width: "100%",
    margin:"1em 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"2em",
    },
  },
  select_container:{
    borderBottom:"2px solid black",
    margin:"1em 0",
    color:theme.palette.primary.dark,
    fontSize:"12px",
    '& > div':{
      width:"100%",
    }
  },
  radio_button_container:{
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    flexDirection:"Row",
    margin:"1em 0",
  },
  customer_details_content: {
    display: "flex",
    flexDirection: "column",
  },
}));