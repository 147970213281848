import React,{ useEffect, useContext, useState } from 'react';
import { getCatalogList } from 'library/catalog/get';
import { getAgents, getUsersList } from 'library/user/get';
import { setCatalogForUser, setCatalogForUsers } from 'library/userCatalog';
import { useStyles } from './Style';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Tooltip} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import TextField from '@material-ui/core/TextField';
import {WLSContext} from 'wls_context.js';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';


import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
    },
  },
};

export default function CustomerCatalogs() {
  const classes = useStyles();
	const [catalogList, setCatalogList] = useState([]);
  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const {ctx} = useContext(WLSContext);
  const [menuItem, setMenuItem] = useState(0);
  const [loadCustomers, setLoadCustomers] = useState(false);
  const [filter, setFilter] = useState({active: true, text: "", catalogs: [], seasons: []});
  
	useEffect(() => {
    getCatalogList().then(x => {
      setCatalogList(x);
      let new_seasons = x.map(e=>e.season);
      setSeasons([...new Set(new_seasons)]);
      setLoaded(true);
    });
    if (ctx.user) {
      if (ctx.user.roles.includes("admin") && !loadCustomers) {
        getAgents().then(setUsers).then( x => {
          setLoaded(true);
        });
      } else {
        getUsersList().then(setUsers).then(x => {
          setLoaded(true);
        });
      }
      }
    // eslint-disable-next-line
	},[reload, ctx.user, loadCustomers]);

  function didChangeCatalogForUser(catalog_id, user_id, new_status) {
    //console.log(new_status ? "Enable" : "Disable", "catalog", catalog_id, "for", user_id);
    setCatalogForUser(user_id, catalog_id, new_status).then(response=> {
      if (response.status === "error") {
        alert(response.error);
      }
      setReload(reload+1);
    });
  }
  const passes_filter = ((catalog) => {
    var show = true;
    if (filter.catalogs.length > 0) {
      return (filter.catalogs.indexOf(catalog.id) > -1);
    }
    if (filter.seasons.length > 0) {
      return (filter.seasons.indexOf(catalog.season) > -1);
    }
    if (filter.active) {
      show = (catalog.is_current === filter.active);
    }
    return show;
  });
  const passes_user_filter = ((user) => {
    var show = true;
    if (show && filter.text !== "") {
      show = user.name.toLowerCase().includes(filter.text.toLowerCase());
    }
    return show;
  });
  const handleChangeCatalogFilter = (e=> {
    setFilter({...filter, catalogs: e.target.value, active: (e.target.value.length === 0)});
  });
  const handleChangeSeason = (e=> {
    setFilter({...filter, seasons: e.target.value, active: (e.target.value.length === 0)});
  });

  const didCloseMenuFor = ((opts) => {
    console.log("Did close menu with opts", opts);
    setMenuItem({anchorEl: null});
    if (opts.catalog_id === undefined) {
      return;
    }
    let req = {catalog_id: opts.catalog_id};
    if (opts.customers === opts.agents && opts.customers !== undefined) {
      req.cascade = true;
    }
    if (req.cascade === undefined) {
      req.cascade = false;
    }
    req.user_ids = users.map(e=>e.id);
    req.enabled = opts.agents;
    setLoaded(false);
    setCatalogForUsers(req).then(response=> {
      if (response.status === "error") {
        alert(response.error);
      }
      setReload(reload+1);
    });
  });
  const openMenuFor = ((catalog, element) => {
    let is_admin = ctx.user.roles.includes("admin") && !loadCustomers;
    setMenuItem({anchorEl: element, catalog: catalog, is_admin: is_admin, handleClose: didCloseMenuFor});
  });
  const sort_name = ((a,b) => (a.name.localeCompare(b.name)));

	return (
    <div>
      <h1 className={classes.text_h1}>Visibility</h1>
      <div className={classes.filter_container}>
        <ToggleButton value="current_catalog" className={classes.filter_current_catalog} aria-label="centered" onChange={e=>{setFilter({...filter, active: !filter.active})}}>
          <Tooltip title="Current catalogs" aria-label="add">
            { filter.active ? 
            <BookmarkIcon style={{color: "#5995c9"}} />
            :
            <BookmarkBorderIcon style={{color: "#5995c9"}} />
            }
          </Tooltip>
        </ToggleButton>
        { ctx.user.roles.includes("admin") ? 
        <ToggleButton value="show_customers" className={classes.filter_current_catalog} aria-label="centered" onChange={e=>{setLoadCustomers(!loadCustomers)}}>
          <Tooltip title="Show customers" aria-label="add">
            <EmojiPeopleIcon style={{color: loadCustomers ? "#5995c9" : "#000000"}} />
          </Tooltip>
        </ToggleButton>
        : null }
        <TextField 
          label="Search"
          className={classes.padding_text_field}
          onChange={e=>{setFilter({...filter, text: e.target.value})}}
        />
        <FormControl className={classes.formControlCatalogs}>
          <InputLabel id="catalog-selector-label">Catalogs</InputLabel>
          <Select
            labelId="catalog-selector-label"
            id="catalog-selector"
            multiple
            value={filter.catalogs}
            className={classes.mdSelect}
            onChange={handleChangeCatalogFilter}
            input={<Input />}
            renderValue={(selected) => `${selected.length} catalogs`}
            MenuProps={MenuProps}
          >
            {catalogList.map((catalog) => (
              <MenuItem key={catalog.id} value={catalog.id}>
                <Checkbox checked={filter.catalogs.indexOf(catalog.id) > -1} />
                <ListItemText primary={catalog.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControlModal}>
          <InputLabel id="seasons-selector-label">Seasons</InputLabel>
          <Select
            labelId="seasons-selector-label"
            id="seasons-selector"
            multiple
            value={filter.seasons}
            className={classes.mdSelect}
            onChange={handleChangeSeason}
            input={<Input />}
            renderValue={(selected) => `${selected.length} seasons`}
            MenuProps={MenuProps}
          >
            {seasons.sort((a,b)=>(a.localeCompare(b))).map((season) => (
              <MenuItem key={season} value={season}>
                <Checkbox checked={filter.seasons.indexOf(season) > -1} />
                <ListItemText primary={season} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </div>
      <div className={classes.form_container}>
        <Paper hidden={loaded}>
        <CircularProgress />
        </Paper>
        <TableContainer hidden={!loaded} component={Paper} className={classes.table_container}>
          <Table stickyHeader className={classes.table} aria-label="sticky table">
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell align="left"></TableCell>
                {catalogList.filter(passes_filter).map((catalog, index) => (
                  <TableCell onClick={e=> openMenuFor(catalog, e.currentTarget)} align="center" key={index}>{catalog.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.filter(passes_user_filter).sort(sort_name).map((user, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  {catalogList.filter(passes_filter).map((catalog, index) => (
                    <TableCell key={catalog.id} align="center">
                      <Checkbox
                       checked={user.catalogs.includes(catalog.id)}
                       onChange={e => didChangeCatalogForUser(catalog.id, user.id, e.target.checked)}
                       color="secondary"
                       />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <VisibilityCatalogMenu {...menuItem} />
    </div>
  );
}

function VisibilityCatalogMenu({catalog, anchorEl, is_admin, handleClose}) {
  const onClose = () => {
    handleClose();
  }
  const onEnableForAll = () => {handleClose({agents: true, customers: true, catalog_id: catalog.id})};
  const onEnableForAgents = () => {handleClose({agents: true, catalog_id: catalog.id})};
  const onDisableForAll = () => {handleClose({agents: false, customers: false, catalog_id: catalog.id})};
  // const onDisableForCustomers = () => {handleClose({customers: false, catalog_id: catalog.id})};

  return (
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={anchorEl != null}
        onClose={onClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onEnableForAll}>Enable for all</MenuItem>
        {is_admin ? <MenuItem onClick={onEnableForAgents}>Enable for agents</MenuItem> : null}
        <MenuItem onClick={onDisableForAll}>Disable for all</MenuItem>
      </Menu>
  )
}