
export async function apiCall(endpoint, data) {
    const response = await fetch(process.env.REACT_APP_WLS_API_URL + endpoint, 
        {method: 'GET',
         mode: 'cors',
         credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         ...data
        }
    );
    if (response.status === 401)
        window.location.href = '/login';
    return response.json();
}

export async function apiCallNoJSON(endpoint, data) {
    const response = await fetch(process.env.REACT_APP_WLS_API_URL + endpoint, 
        {method: 'GET',
         mode: 'cors',
         credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         ...data
        }
    );
    if (response.status === 401)
        window.location.href = '/login';
    return response;
}

export async function apiReq({endpoint, method, body, params}) {
    if (params !== undefined) {
        let url_encoded_params = new URLSearchParams(params);
        endpoint = `${endpoint}?${url_encoded_params.toString()}`
    }
    let response = new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_WLS_API_URL}${endpoint}`, {
            method: method,
            mode: 'cors',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: body !== undefined ? JSON.stringify(body) : null
        }).then((r) => {
        if (r.ok) {
            r.json().then((r) => resolve(r));
        } else {
            r.json().then((r) => reject(r.detail));
        }
        });
    })
    return response;
}
export const apiGet = async ({endpoint, params}) =>  ( await apiReq({endpoint, method: 'GET', params}));
export const apiPost = async ({endpoint, body}) =>  ( await apiReq({endpoint, method: 'POST', body}));
export const apiPut = async ({endpoint, body}) =>  ( await apiReq({endpoint, method: 'PUT', body}));
export const apiDelete = async ({endpoint}) =>  ( await apiReq({endpoint, method: 'DELETE'}));
export const apiPatch = async ({endpoint, body}) =>  ( await apiReq({endpoint, method: 'PATCH', body}));
