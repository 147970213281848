import React, { useEffect } from 'react';
import {getOrder} from 'library/order/get';
import {OrderLinesMap} from 'library/wls_helper';
import GarmentMatrix from './GarmentMatrix';
import {styles} from 'styles/components/linesheet';
import Summary from 'components/linesheet/Summary';
import {store} from 'wls_context';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function LinesheetLayout() {
  const classes = styles();
  const [loaded, setLoaded] = React.useState(false);
  const [lightbox, setLightbox] = React.useState({open: false, garment: ""});

  let order_id = window.location.pathname.split("/").pop();
  
  useEffect(() => {
    store.subscribe(on_dispatch);
    setLoaded(true);
    getOrder(order_id).then(e=> {
      // Sort garments
      e.garments.sort((a,b) => a.number - b.number);
      // Map lines from {sku: qtty} to {generic: {sku_suffix: qtty}}
      let lines = OrderLinesMap(e.lines);
      // Update redux state
      store.dispatch({
        type: "SET_ORDER",
        ...e,
        lines: lines
        });
    });
  }, [order_id]);

  const on_dispatch = () => {
    let event = store.getState().wls.lightbox;
    if (event === undefined || event.open === false) {return;}
    setLightbox(event);
  }

  const onCloseLightbox = () => {
    setLightbox({open: false, pictures: []});
    store.dispatch({type: "SET_LIGHTBOX", lightbox: {open: false, pictures: []}});
  }

  if (!loaded) {
    return (<></>)
  }


  return (
    <div className={classes.main}>
      <div className={classes.main_content}>
        <div className={classes.left_column}>
          <Summary />
        </div>
        <div className={classes.right_column}>
          <GarmentMatrix />
        </div>
      </div>
      {
        lightbox.open ? 
        <Lightbox
          open={lightbox.open}
          close={onCloseLightbox}
          carousel={ {finite: true, preload: lightbox.pictures.length}}
          slides={ lightbox.pictures }
          plugins={[Thumbnails]}
        /> : <></>
      }

    </div>
  );
}
