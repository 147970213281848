import {apiCall} from 'library';

export function reuploadCatalog(catalog_id, catalog_file, service_date, catalog_name, catalog_type, is_current, drop) {
    const FD  = new FormData();
    FD.append("catalog", catalog_id);
    FD.append("name", catalog_name);
    FD.append("catalog_type", catalog_type );
    FD.append("is_current", is_current );
    FD.append("drop", drop );
    if ( service_date !== "" && service_date !== null) {
        FD.append("service_date", service_date);
    }
    if (catalog_file !== undefined){
        FD.append("catalog_path", catalog_file);
    }
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/mgmt/catalog/reupload_async',
    	{method: 'POST',
    	 mode: 'cors',
    	 credentials: "include",
         body: FD
    	}
    ).then(r=> {
        return r.json()
    });
}

export function savePermissions(catalog_id, wls_enabled, ls_enabled, is_current) {
    return apiCall('/api/catalog/visibility', 
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": catalog_id,
                "wls_enabled": wls_enabled,
                "ls_enabled": ls_enabled,
                "is_current": is_current,
                }
            )
        }
    );
}

export function saveUserCatalog(catalog_id, user, enabled) {
    return apiCall('/api/user/catalog', 
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": catalog_id,
                "enabled": enabled
                }
            )
        }
    );
}
