import {apiCall, apiCallNoJSON} from 'library';

export function getCatalogList() {
	return apiCall('/api/catalogs/list_simple');
}

export function getCatalogListAdmin() {
	return apiCall('/api/catalogs/list_admin');
}
export function getCatalog({catalog_id, price_list}) {
	var qs = "";
	if (price_list !== null && price_list !== undefined && price_list !== "") {
		qs = `?pl=${price_list}`;
	}
	return apiCall(`/api/catalog/list/${catalog_id}${qs}`);
}

export function getDesignCatalog({catalog_id, price_list}) {
	var qs = "";
	if (price_list !== null && price_list !== undefined && price_list !== "") {
		qs = `?pl=${price_list}`;
	}
	return apiCallNoJSON(`/api/catalog/preview/txt/${catalog_id}${qs}`);
}
export function getStockCatalog({catalog_id, price_list}) {
	var qs = "";
	if (price_list !== null && price_list !== undefined && price_list !== "") {
		qs = `?pl=${price_list}`;
	}
	return apiCallNoJSON(`/api/catalog/stocks/pdf/${catalog_id}${qs}`);
}