import React,{ useEffect, useContext } from 'react';
import { getCatalogList } from 'library/catalog/get';
import { createUser } from 'library/user/create';
import { FormGroup, TextField, Button } from '@material-ui/core';
import { useStyles } from './Style_create';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import ErrorModal from 'components/errorModal';
import AlertModal from 'components/alertModal';
import { user_roles } from 'library/constants';
import {notnull} from 'library/wls_helper';
import { WLSContext } from 'wls_context';
import {priceslists, warehouses} from 'library/constants.js';
import { Select, MenuItem } from "@material-ui/core";

export default function CustomerCreator() {
  const classes = useStyles();
  const [catalogs, setCatalogs] = React.useState([]);
  const [catalogsEnabled, setCatalogsEnabled] = React.useState([]);
  const [userFields, setUserFields] = React.useState({name:"",email:"",catalog:"",is_wholesale:"true", send_email:false, roles: ['wls'], extra_info: "", warehouse: "0001", pricelist: "None"});
  const [errorStatus, setErrorStatus] = React.useState({failed:false, message:"",});
  const [alertStatus, setAlertStatus] = React.useState({show:false, message:"",});
  const {ctx} = useContext(WLSContext);

  useEffect(() => {
    if (catalogs.length === 0) {
      getCatalogList().then(setCatalogs)
    }
  },[catalogs]);

  function handleSubmit(event) {
    event.preventDefault();

    let fixed_sap_id = userFields.sap_id ?  userFields.sap_id.trim() : null;
    if (notnull(fixed_sap_id) && fixed_sap_id !== "") {
      if (userFields.sap_id.length > 4)
        fixed_sap_id = ("000000" + userFields.sap_id).substr(-10);
      if (userFields.sap_id.length < 4)
        fixed_sap_id = ("0000" + userFields.sap_id).substr(-4);
    }
    if (process.env.REACT_APP_WLS_PREVENT_EMAIL === "yes") {
      userFields.send_email = false;
    }
    createUser(userFields.name, userFields.email, fixed_sap_id, catalogsEnabled, userFields.is_wholesale, userFields.send_email, userFields.roles, userFields.extra_info, userFields.warehouse, userFields.pricelist).then(e=> {
      if(e.status === "success"){
        setAlertStatus({show:true, message:<>User created: <a href={"/admin/user/" + e.user_id}>view it</a></>});
      }else{
        setErrorStatus({failed:true, message:e.error});
      }
    })
  }

  const handleErrorClose = () => {
    setErrorStatus({failed:false, message:"",});
  };

  const handleAlertClose = () => {
    setAlertStatus({show:false, message:"",});
  };

  const didChangeCatalog = (catalog_id, enabled) => {
    if (enabled) {
      return setCatalogsEnabled([...catalogsEnabled, catalog_id]);
    }
    setCatalogsEnabled(catalogsEnabled.filter(c => c !== catalog_id))
  }
  const didChangeWarehouse = (e) => {
    setUserFields({...userFields, warehouse: e.target.value});
  }
  const didChangePriceList = (e) => {
    setUserFields({...userFields, pricelist: e.target.value});
  }
  const didChangeRole = (e) => {
    let name = e.target.name;
    if (e.target.checked) {
      return setUserFields({...userFields, "roles": [...userFields.roles, name]});
    }
    setUserFields({...userFields, "roles": userFields.roles.filter(c => c !== name)});
  }

  const available_roles = () => {
    if (ctx.user === undefined) {return [];}
    if (ctx.user.roles.includes('admin')) {
      return user_roles;
    }
    return [{id:'wls', formDisplayText: 'Can use WLS'}];
  }

  const available_pricelist = () => {
    if (ctx.user === undefined) {return [];}
    if (ctx.user.roles.includes('admin')) {
      return priceslists;
    }
    return [ctx.user.pricelist];
  }

  const available_warehouses = () => {
    if (ctx.user === undefined) {return [];}
    if (ctx.user.roles.includes('admin')) {
      return warehouses;
    }
    return [ctx.user.warehouse];
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>Add new customer</h1>
      <form className={classes.form} onSubmit={handleSubmit}>
      <FormGroup>
      <div className={classes.form_container}>
          <div className={classes.form_left}>
            {/* User fields */}
            <TextField label="Name" className={classes.form_text_field} onChange={e => setUserFields({...userFields,name:e.target.value})} />
            <TextField label="Email" className={classes.form_text_field} onChange={e => setUserFields({...userFields,email:e.target.value})} />
            <TextField label="ID SAP" className={classes.form_text_field} onChange={e => setUserFields({...userFields,sap_id:e.target.value})} />
            <TextField label="Extra notes" className={classes.form_text_field} onChange={e => setUserFields({...userFields,extra_info:e.target.value})} />
            {/* Wholesale or store */}
            <RadioGroup className={classes.radio_button_container} value={userFields.is_wholesale} onChange={e => setUserFields({...userFields,is_wholesale:e.target.value})}>
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Wholesale" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="Store" />
            </RadioGroup>
            {/* warehouse and price list */}
            <div className={classes.wh_pricelist} >
              <InputLabel id="pricelistLabel">Price list</InputLabel>
              <Select
                  labelId="pricelistLabel"
                  id="pricelist-select"
                  value={userFields.pricelist ? userFields.pricelist : ""}
                  onChange={didChangePriceList}
                >
                  <MenuItem value="None">None</MenuItem>
                  {available_pricelist().map(e=> (
                    <MenuItem key={e} value={e}>{e}</MenuItem>
                  ))}
              </Select>
              <InputLabel id="warehouseLabel">Warehouse</InputLabel>
              <Select
                  labelId="warehouseLabel"
                  id="warehouse-select"
                  value={userFields.warehouse ? userFields.warehouse : ""}
                  onChange={didChangeWarehouse}
                >
                  <MenuItem value="None">None</MenuItem>
                  {available_warehouses().map(e=> (
                    <MenuItem key={e} value={e}>{e}</MenuItem>
                  ))}
              </Select>
            </div>
            {/* Send email */}
            <FormControlLabel className={classes.send_email_checkbox} control={
                <PrimaryCheckbox
                  checked={userFields.send_email}
                  onChange={e => setUserFields({...userFields,send_email:e.target.checked})}
                  color="primary"
                  />}
              label="Send email"/>
            {/* Roles */}
            <div className={classes.select_container}>
              <InputLabel id="catalog-select-label" className={classes.form_label}>Roles</InputLabel>
             {available_roles().map(role =>(
              <FormControlLabel key={role.id} control={
                <PrimaryCheckbox
                  checked={userFields.roles.includes(role.id)}
                  name={role.id}
                  onChange={didChangeRole}
                  color="primary"
                  />}
              label={role.formDisplayText}/>
              ))}
            </div>
          </div>
          <div className={classes.form_right}>
            {/* Catalog selector */}
            <div className={classes.select_container}>
              <InputLabel id="catalog-select-label" className={classes.form_label}>Catalogs enabled</InputLabel>
              {catalogs.map(catalog => (
                <FormControlLabel key={catalog.id} control={
                  <PrimaryCheckbox
                    checked={catalogsEnabled.includes(catalog.id)}
                    onChange={e => didChangeCatalog(catalog.id, e.target.checked)}
                    color="primary"/>}
                  label={catalog.name}/>
               ))}
            </div>
          {/* Create button */}
          <Button className={classes.button} variant="contained" type="submit" onClick={handleSubmit}>
            Create
          </Button>
          </div>

      </div>
      </FormGroup>
      </form>
      <ErrorModal title="Oops!" body={errorStatus.message} open={errorStatus.failed} onClose={handleErrorClose}/>
      <AlertModal title="User created" body={alertStatus.message} open={alertStatus.show} onClose={handleAlertClose}/>
    </div>
  );
}
