import {apiCall} from 'library';

export async function updateUserRoles(id, roles) {
    return apiCall('/api/user/roles',
    	{method: 'PUT',
         body: JSON.stringify(
                {
                "id": id,
                "roles": roles,
                }
            )
    	}
	);
}

export async function updateUserAgents(id, agents) {
    return apiCall('/api/user/agents',
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": id,
                "agents": agents,
                }
            )
        }
    );
}

export async function updateUserSapId(id, sap_id) {
    return apiCall('/api/user/sap_id',
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": id,
                "sap_id": sap_id,
                }
            )
        }
    );
}

export async function updateUserPassword(id, password) {
    return apiCall('/api/user/password',
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": id,
                "password": password,
                }
            )
        }
    );
}
export async function updateUserProfile(user) {
    return apiCall('/api/user',
        {method: 'PUT',
            body: JSON.stringify(
                {
                    "id": user.id,
                    "name": user.name,
                    "email": user.email,
                    "roles": [
                        ...user.roles
                    ],
                    "sap_id": user.sap_id,
                    "catalogs": [
                        ...user.catalogs
                    ],
                    "extra_info": user.extra_info,
                    "warehouse": user.warehouse,
                    "pricelist": user.pricelist
                }
            )
        }
    );
}
export async  function deleteUser(toDelete) {
    return apiCall('/api/user',
        {method: 'DELETE',
         body: JSON.stringify(
                {
                    ...toDelete
                }
            )
        }
    );
}
