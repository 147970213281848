import React, { useEffect, useState } from 'react';
import { getOrderList, getOrderListExcel } from 'library/order/get';
import UnblockOrderModal from './UnblockOrderModal';
import OrderListFilterModal from './OrderListFilterModal';
import { useStyles } from './style';
import OrderListTable from './OrderListTable';
import OrderListFilterBar from './OrderListFilterBar';
import { connect } from 'react-redux';

function mapStateToProps(state, ownProps) {
  const { order_menu, dispatch } = state;
  return { loaded: order_menu.loaded, dispatch: dispatch }
}

function OrderList({loaded, dispatch}) {
  const classes = useStyles();
  const [filter, setFilter] = useState({sap_status: null, current_catalog: true, catalogs: [], seasons: []});
  const [active_modal, setActiveModal] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [orders, setOrderList] = useState([]);

  useEffect(() => {
  	if (loaded && firstLoad) { return; }
    getOrderList({archived: false, current_catalog: filter.current_catalog}).then(e=> {
      dispatch({type: "SET_LOADED"});
      setFirstLoad(true);
      if (e.status === "success") {
        //const makeRepeated = (arr, repeats) => Array.from({ length: repeats }, () => arr).flat();
      	//setOrderList(makeRepeated(e.orders, 20));
        setOrderList(e.orders);
      }
    })
    // eslint-disable-next-line
  },[orders, loaded]);

  const paramsForFilter = (a_filter) => {
    var rd = {
      current_catalog: a_filter.current_catalog,
      catalogs: a_filter.catalogs,
      archived: a_filter.archived,
      seasons: a_filter.seasons,
    };
    // SAP Status
    if (a_filter.sap_status === undefined) {
      rd.sap_status = "0";
    } else if (a_filter.sap_status === "open") {
      rd.sap_status = "0";
    } else if (a_filter.sap_status === "all") {
      rd.sap_status = "0,1,2,3";
    } else if (a_filter.sap_status === "sent") {
      rd.sap_status = "2,3";
    } else if (a_filter.sap_status === "in_sap") {
      rd.sap_status = "1";
    } else if (a_filter.sap_status === "finished") {
      rd.sap_status = "0";
      rd.finished = true;
    }
    if (a_filter.begin !== undefined) {
      rd.begin = a_filter.begin;
    }
    if (a_filter.end !== undefined) {
      rd.end = a_filter.end;
    }

    return rd;
  }

  const didChangeStatusFilter = (new_filter) => {
    const def_params = paramsForFilter(new_filter);
    let changed_query = (new_filter.query !== filter.query);

    if (!changed_query) { // Query is checked in browser, the rest in backend
      setFirstLoad(false);
      getOrderList({...def_params}).then(e=> {
        setFirstLoad(true);
        if (e.status === "success") {
          setOrderList(e.orders);
        }
      });
    }
    setFilter(new_filter);
  }

  const onDownloadExcel = () => {
    const rowFilter = (order) => {
      if (filter.query) {
        let lower_case_query = filter.query.toLowerCase();
        let order_as_string = JSON.stringify(order).toLowerCase();
        if (!order_as_string.includes(lower_case_query))
          return false;
      }
      return true;
    };
    dispatch({type: "SHOW_MESSAGE", payload: {severity: "success", message: "Downloading excel"}});
    let order_ids = orders.filter(rowFilter).map(x=>x.id);
    getOrderListExcel(order_ids).catch(x=>{
      /*console.log(x);*/
      dispatch({type: "SHOW_MESSAGE", payload: {severity: "error", message: x}});
    });
  };

  return (
    <div className={classes.main}>
      <div className={classes.main_content}>
        <OrderListFilterBar 
          filter={filter}
          onFilterChange={didChangeStatusFilter}
          openModal={() => {setActiveModal("filter")}}
          onDownloadExcel={onDownloadExcel}
        />
        <OrderListTable 
          loaded={loaded && firstLoad}
          orders={orders}
          filter={filter}
        />
      </div>
      <OrderListFilterModal filter={filter} onFilterChange={didChangeStatusFilter} show={active_modal === "filter"} onClose={()=>setActiveModal("")}/>
      <UnblockOrderModal />
    </div>
  );
}

export default connect(mapStateToProps)(OrderList)