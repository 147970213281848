import React from 'react';

export default function ProductDetailSummary({classes, product}) {

	return (
    <div className={classes.product_body_right}>
      {product.name ? 
      <SummaryLine classes={classes} text="Product name: " value={product.name} />
      : null }
      <SummaryLine classes={classes} text="Ref : " value={product.generic} />
      <SummaryLine classes={classes} text="Delivery : " value={`${product.drop} ${product.collection ? product.collection : ""}`} />
      {(product.made_in !== null && product.made_in !== "None") && <SummaryLine classes={classes} text="Made in : " value={product.made_in} />}
      <SummaryLine classes={classes} text="Description : " value={product.description} />
      <SummaryLine classes={classes} text=""  value={product.composition} />
      { (product.wh_price !== null && product.wh_price > 0) ? <SummaryLine classes={classes} text="Wholesale price: " value={`${product.wh_price}${product.currency}`} /> : null }
      { (product.rt_price !== null && product.rt_price > 0) ? <SummaryLine classes={classes} text="SRP : " value={`${product.rt_price}${product.currency}`} /> : null }
      { product.with_stocks && false ? <span className={classes.stock}> Stock </span> : null }
      { product.large_description !== "" && product.large_description !== "None" ? <span className={classes.long_description}>{product.large_description}</span> : null }
    </div>
  );
}

function SummaryLine({classes, text, value}) {
	return (
		<p className={classes.text_property_name}>
			{text}
			<span
				className={classes.text_property_value}>
				{value}
			</span>
		</p>
		);
}