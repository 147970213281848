import React from 'react';
import ProductDetailSummary from './Summary';
import ProductDetailFooter from './Footer';
import { connect } from 'react-redux';
import { styles as detail_style } from 'styles/components/productDetail';

function mapStateToProps(state, ownProps) {
  const { dispatch } = state;
  const { product, wanted } = ownProps;
  return { product: product, wanted: wanted, dispatch: dispatch }
}

const GarmentDetail = React.memo(({product, wanted, dispatch}) => {
  return GarmentDetailUnCached({product, wanted, dispatch})
}, (prevProps, nextProps) => {
  let same_generic = prevProps.product.generic === nextProps.product.generic;
  let same_wanted = prevProps.wanted && nextProps.wanted && (prevProps.wanted.length === nextProps.wanted.length)
  return ( same_generic && same_wanted );
});

export const GarmentDetailUnCached = ({product, wanted, dispatch}) => {
  const classes = detail_style();

  function onColorClicked(e){
    let color_line = e.currentTarget.getAttribute('data-color-line');
    dispatch({type: "TOGGLE_WANTED", item: color_line})
  }

  function onNumberChange(e){
    let sku = e.target.getAttribute('data-sku');
    let new_quantity = parseInt(e.target.value);

    let is_new_value = !isNaN(new_quantity);
    let is_remove_value = (isNaN(new_quantity) || new_quantity===0);
    if (sku === "key") {
      alert("Key in onNumberChange!!")
    }
    if (is_new_value) {
      if (product.with_stocks) {
        let sku_obj = product.skus.filter(x=> x.sku === sku)[0];
         if (sku_obj.stock && new_quantity > sku_obj.stock) {
          dispatch({type: "SHOW_MESSAGE", payload: {message: "Not enough stock for " + sku, severity: "error"}})
        }
      }
      dispatch({type: "SET_QUANTITY", garment: product.generic, sku: sku, quantity: new_quantity})
    }
    if (is_remove_value) {
      dispatch({type: "REMOVE_SKU", garment: product.generic, sku: sku})
    }
  }

  const picturesForLightbox = (pm, generic) => {
    if (Object.keys(pm).length === 0) {
      return [{ src: `https://wls.nicethings.es/images/garments/none.jpg` }];
    }
    
    let rd = [{ src: `https://wls.nicethings.es/images/garments/${generic}.jpg` }];
    for (const [color, quantity] of Object.entries(pm)) {
      let color_appendix = `${generic}_${color}`
      for (let i = 1; i <= quantity; i++) {
        rd.push({ src: `https://wls.nicethings.es/images/garments/${color_appendix}_${i}.jpg` });
      }
    }
    return rd;
  };

  const onImageClick = () => {
    dispatch({type: "SET_LIGHTBOX", lightbox: {open: true, pictures: picturesForLightbox(product.pictures, product.generic)}});
  }

  let image_name = product.generic;
  if (Object.keys(product.pictures).length === 0) {
    image_name = "none";
  }

  return (
      <div className={classes.container}>
        <div className={classes.product_body}>
          <div className={classes.product_body_left} onClick={onImageClick} >
            <img alt={`${product.generic}`} className={classes.product_img} src={"https://wls.nicethings.es/images/thumbnails/"+image_name+".jpg"} />
          </div>
          <ProductDetailSummary
            classes={classes}
            product={product}
          />
          <ProductDetailFooter 
            classes={classes}
            product={product}
            wanted={wanted}
            onColorClicked={onColorClicked}
            onLoseFocus={onNumberChange}
          />
        </div>
      </div>
  );
}

export default connect(mapStateToProps)(GarmentDetail)