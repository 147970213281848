import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Box,
  Button,
  IconButton
} from '@material-ui/core';
import WLSTable from 'components/WLSTable';
import AddAgentModal from './AddAgentModal';
import { getAgents } from 'library/user/get';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { updateUserAgents } from 'library/user/edit';
import {cardStyles} from './style';

export default function Agents({user, onUpdate}) {
  const classes = cardStyles();
  const [openAddAgentModal, setOpenAddAgentModal] = React.useState(false);
  const [available_agents, setAvailableAgents] = React.useState([]);

  React.useEffect(() => {
    getAgents().then(setAvailableAgents);
  }, [user]);
  
  const columns = [
    { id: 'name', align: "left", disablePadding: true, label: 'Name', rowContent: (c) => (
      <a href={`/admin/user/${c.id}`}>{c.name}</a>
      ) },
    //{ id: 'email', align: "left", disablePadding: true, label: 'Email' },
    { id: 'actions', align: "left", disablePadding: true, label: '', rowContent: c => (
      <IconButton color="primary" onClick={e => {handleRemoveAgent(c.id)}}>
        <HighlightOffIcon />
      </IconButton>
      ) },
  ];

  const shouldShow = agent => { return user.agents.includes(agent.id);  };

  const handleAddAgent = () => {
    setOpenAddAgentModal(true);
  };

  const handleAddAgentClose = (value) => {
    setOpenAddAgentModal(false);
    if (value) {
      updateUserAgents(user.id, [...user.agents, value]).then(onUpdate);
    }
  };
  const handleRemoveAgent = (value) => {
    console.log("Removing agent", value);
    let new_agents = user.agents.filter(x=> (x !== value));
    updateUserAgents(user.id, new_agents).then(onUpdate);
  };

  return (
    <Paper elevation={0} className={classes.root}>
    <Box className={classes.headerBox} display="flex">
      <p className={classes.headerTitle}>Agents</p>
      <Button className={classes.actionButton} color="primary" onClick={handleAddAgent}>
        + Add agent
      </Button>
    </Box>
    {user.agents.length > 0 
      ?
      <WLSTable 
        classes={classes}
        columns={columns}
        rows={available_agents}
        shouldShowRow={shouldShow}
      />
      : <></>
    }
      <AddAgentModal 
        open={openAddAgentModal}
        onClose={handleAddAgentClose}
        user={user}
        agents={available_agents}
      />
    </Paper>
  );
};

Agents.propTypes = {
  user: PropTypes.object,
};
