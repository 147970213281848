import {apiCall} from 'library';

export async function current() {
    return apiCall('/api/me');
}

export async function getUsersList() {
    return apiCall('/api/wls_users');
}

export async function getUser(user_id, include_num_orders=false, include_addresses=false) {
  let query_options = [];
	if (include_num_orders)
		query_options.push('include_num_orders=true');
	if (include_addresses) {
			query_options.push('include_addresses=true');
			query_options.push('include_salesorg=true');
	}
  return apiCall(`/api/user/${user_id}?${query_options.join('&')}`);
}

export async function getAgentUsers(user_id) {
    return apiCall(`/api/users_for_agent/${user_id}`);
}

export async function getAgents() {
    return apiCall(`/api/agents`);
}

export async function getCustomerList({include_num_orders, include_addresses}) {
	let query_options = [];
	if (include_num_orders)
		query_options.push('include_num_orders=true');
		query_options.push('num_catalogs=true');
	if (include_addresses) {
			query_options.push('include_addresses=true');
			query_options.push('include_salesorg=true');
	}
	return apiCall('/api/wls_users?'+query_options.join('&'));
}
