import {apiCall} from 'library';

export async function doLogin(username, password) {
    return apiCall('/auth/token',
        {method: 'POST',
         body: JSON.stringify(
                {
                "username": username,
                "password": password
                }
            )
        });
}

export async function doLogout() {
    apiCall('/logout').then(r=> {
        window.location.href = '/login';
    });
}
