import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';

function mapStateToProps(state, ownProps) {
  const { alert_bar, dispatch } = state;
  return { open: Boolean(alert_bar.show), severity: alert_bar.severity, message: alert_bar.message, dispatch: dispatch }
}

function AlertSnackBar({open, severity, message, dispatch}) {
  const [show, setShow] = React.useState(open);
  useEffect(() => {
    setShow(open);
  },[open]);

  const handleClose = () => {
    dispatch({type: "ALERT_CLOSE"});
  };

  if (!show) {return <></>}

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert variant="filled" severity={severity}>
        {message}
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </MuiAlert>
    </Snackbar>
  );
}

export default connect(mapStateToProps)(AlertSnackBar)