import { makeStyles } from '@material-ui/core';
import { TextField} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  search_field:{
    '& .MuiOutlinedInput-root:hover fieldset':{
      border: `1px solid ${theme.palette.text.hint}`,
    },
    '& fieldset':{
      borderRadius:"100px",
    },
    '& .MuiInputBase-input': {
      padding: "10px 14px",
      //padding: "10px 42px 10px 0px",
      position: 'relative',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputAdornment-positionEnd': {
      color: theme.palette.grey[300],
      position: 'absolute',
      right: '14px',
    },
  },
}));

export default function SearchField({onChange, value, className}) {
  const classes = useStyles();
  return (
      <TextField
        className={clsx(classes.search_field, className)}
        label="Search"
        variant="outlined"
        onChange={onChange}
        value={value}
        InputProps={{
          //startAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
        }}
      />)
}