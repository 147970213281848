import React, { useEffect, useState } from 'react';
import { useStyles } from '../Style';
import { Tooltip } from '@material-ui/core';
import { getCustomerList } from 'library/user/get';
import { updateUserSapId } from 'library/user/edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckbox from 'components/primaryCheckbox';
import WLSTable from 'components/WLSTable';
import AssignSapIdModal from 'components/AssignSapIdModal';
import Button from '@material-ui/core/Button';
import SearchField from 'components/SearchField';

export default function CustomerList() {
  const classes = useStyles();
  const [filter, setFilter] = useState(null);
  const [sap_filter, setSAPFilter] = useState(false);
  const [sap_id_modal_open, setSAPIdModalOpen] = useState(false);
  const [sap_id_modal_user, setSAPIdModalUser] = useState(false);
  const [customers, setCustomerList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) { return; }
    getCustomerList({"include_num_orders": true}).then(e=> {
      setLoaded(true);
      setCustomerList(e);
    })
  },[customers, loaded]);

  const doSearch = e => {
    if (!e.target.value) {
      setFilter(null); return;
    }
    setFilter(e.target.value);
  };

  const onUnassignedSAPIDClick = (user_id) => {
    setSAPIdModalUser(user_id);
    setSAPIdModalOpen(true);
  }

  const didAssignSAPId = (sap_id) => {
    let fixed_sap_id = sap_id;
    if (sap_id.length > 4)
      fixed_sap_id = ("000000" + sap_id).substr(-10);
    if (sap_id.length < 4)
      fixed_sap_id = ("0000" + sap_id).substr(-4);
    
    setSAPIdModalOpen(false);
    updateUserSapId(sap_id_modal_user, fixed_sap_id);
    setLoaded(false);
  }

  const shouldShow = customer => {
    if (filter !== null)
      if (!JSON.stringify(customer).toLowerCase().includes(filter.toLowerCase()))
        return false;

    if (sap_filter) {
      return customer.sap_id === null;
    }
    return true;
  };
  
  const columns = [
    { id: 'name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'email', align: "left", disablePadding: true, label: 'Email' },
    { id: 'sap_id', align: "left", disablePadding: true, label: 'SAP ID', rowContent: (c) => (c.sap_id ? <p>{c.sap_id}</p> : <Button onClick={(e)=>{ onUnassignedSAPIDClick(c.id) }}>Unassigned</Button>)},
    { id: 'catalogs', align: "left", disablePadding: true, label: 'Catalogs', rowContent: (c) => (c.num_catalogs) },
    { id: 'num_orders', align: "left", disablePadding: true, label: 'Orders' },
    { id: 'actions', align: "right", disablePadding: false, label: 'Actions', rowContent: (c) => (
      <div>
        <a className={classes.table_action_icon} href={`/admin/user/${c.id}`}>
          <Tooltip title="Detail" aria-label="add">
            <VisibilityIcon></VisibilityIcon>
          </Tooltip>
        </a>
        <a className={classes.table_action_icon} href={`/admin/order/for/${c.id}`}>
          <Tooltip title="Create order" aria-label="add">
            <AddShoppingCartIcon></AddShoppingCartIcon>
          </Tooltip>
        </a>
      </div>
      )},
  ];

  return (
    <div className={classes.root}>
      <div className={classes.main_content}>
        <div className={classes.filterBox}>
          <h1 className={classes.text_h1}>Customers ({customers.length})</h1>
          <div className={classes.searchFieldBox}>
            <FormControlLabel labelPlacement="start" control={<PrimaryCheckbox checked={sap_filter} onChange={e => setSAPFilter(e.target.checked)} color="primary"/>} label="Without SAP ID"/>
            <SearchField className={classes.search_form} onChange={doSearch}/>
          </div>
        </div>

        <div className={classes.right_column}>
          <WLSTable 
            classes={classes}
            columns={columns}
            rows={customers}
            shouldShowRow={shouldShow}
          />
        </div>
      </div>
      <AssignSapIdModal
        active={sap_id_modal_open}
        classes={classes}
        onClose={(e)=>{setSAPIdModalOpen(false)}}
        onAdd={didAssignSAPId}
      />
    </div>
  );
}
