import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  main: {
    width:"100%",
    minHeight:"calc(90vh - 2em)",
    padding:"0 !important",
    backgroundColor:"transparent !important",
  },
  main_content: {
    width:"100%",
    padding:"0",
    fontFamily:theme.typography.fontFamily,
    fontSize:"10px",
    display:"flex",
    flexWrap:"wrap",
  },
  filter_container_horizontal: {
    width:"100%",
    marginBottom:"2em",
    padding:"2em",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:theme.wls.mainBg.primary,
  },
  table_container_bg: {
    width:"100%",
    display:"flex",
    flexWrap:"wrap",
    backgroundColor: theme.wls.mainBg.primary,
    padding:"2em",
    [theme.breakpoints.down('sm')]:{
      padding:"2em",
      marginLeft:"0",
    },
  },
  loading_spinner: {
    margin: "auto",
  },
  text_h1: {
    fontSize:"18px",
    fontWeight:"600",
    color:theme.palette.secondary.dark,
    margin:"1em 0",
    marginTop:".5em",
  },
  text_base: {
    color:theme.palette.details,
  },
  button:{
    backgroundColor:theme.palette.details,
    color:theme.palette.primary.light,
    borderRadius:"0",
    width:"150px",
    fontSize:"12px",
    [theme.breakpoints.down('md')]: {
      marginTop:"1em",
    },
    '&:hover':{
      backgroundColor:theme.palette.primary.dark,
    }
  },
  dateFilterInput:{
    margin:"1em",
    '& .MuiInputBase-input': {
      padding: "0",
      '&::-webkit-calendar-picker-indicator': {
        display: "none",
        "-webkit-appearance": "none",
      }
    },
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  icon_view:{
    color:theme.palette.backgroundDetails,
    margin: "0 0.5em",
    '&:hover':{
      color:theme.palette.secondary.main,
    }
  },
  icon_pdf:{
    color:"#f27e76",
    margin: "0 0.5em",
    '&:hover':{
      color:"#f44336",
    }
  },
  icon_download:{
    color:theme.palette.colorText,
    margin: "0 0.5em",
    '&:hover':{
      color:"#4caf50",
    }
  },
  icon_open:{
    color: "#e0d850",
  },
  icon_filter_active: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  searchFilterBox: {
    display: "flex",
    alignItems: "center",
  },
  toggleButtonGroup: {
    margin: "0 16px",
    '& .MuiToggleButton-root:first-child':{
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRadius: "100px",
    },
    '& .MuiToggleButton-root:last-child':{
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderRadius: "100px",
    },
    '& button': {
      padding: "10px 12px",
      height: "39px",
      '&.Mui-selected': {
        backgroundColor: theme.wls.mainBg.secondary,
      }
    }
  },
  dateFilterBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  downloadListIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: "none",
      color: theme.palette.primary.light,
      transition: "all .235s ease",
    }
  },
  formControlModal: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  modalFilterBox:{
    display: "flex",
  },
  mdSelect: {
    //minWidth: "10em",
  },
  menuButton: {
    width:"30px",
    height:"15px",
    border:"none",
    background:"transparent",
    position:"relative",
    cursor:"pointer",
    margin:"4px",
    "&:focus": {
      outline:"none",
    },
    "&:before, &:after, & span": {
      width: "12.5%",
      height: "25%",
      borderRadius: "100px",
      position: "absolute",
      background: theme.palette.primary.main,
      transform: "rotate(0deg)",
      transition: "all 0.4s",
      left: "1%",
      right: "1%",
      margin: "0 auto",
    },
    "&:before, &:after": {
      content: '""',
    },
    "&:after": {
      top: "-10%",
      marginBottom: "0px",
    },
    "&:before": {
      bottom: "-10%",
      marginBottom: "0px",
    },
    "& span": {
      top: "50%",
      marginTop: "-2px",
    },
    '&.OrderListActiveButton': {
      "&:before, &:after": {
        width: "7.5%",
        height: "15%",
      },
      "&:after": {
        transform: 'rotate(135deg) translate(6px, -6px)',
        width: "70%",
      },
      "&:before": {
        transform: 'rotate(225deg)',
        bottom: '50%',
        marginBottom: '-2px',
        width: '70%',
      },
      "& span": {
        display: "none",
      },
    },
  },
}));