import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: theme.wls.mainBg.primary,
    flexWrap: "wrap",
  },
  text_h1:{
    marginTop:"0",
    padding: "1em",
    fontFamily:theme.palette.typography,
    color: theme.wls.texts.title,
    fontSize:"20px",
  },
  button:{
    width: "100%",
    margin:"1em 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"2em",
    },
  },
  container: {
    height: "auto",
    alignContent: "flex-start",
    flexDirection: "column",
  },
  grid_item: {
    width: "100%",
    "&:after": {
      content: "",
      display: "block",
      paddingBottom: "100%",
    }
  },
}));