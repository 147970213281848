export function setCatalogForUser(user_id, catalog_id, enabled) {
	return fetch(process.env.REACT_APP_WLS_API_URL + '/api/catalog/for_user', 
    	{method: 'PUT',
    	 mode: 'cors',
    	 credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(
                {
                "catalog_id": catalog_id,
                "user_id": user_id,
                "action": enabled ? "enable" : "disable",
                }
            )
    	}
    ).then(r=> {
        if (r.status === 401)
            window.location.href = '/login';
        return r.json()
    });
}

export function setCatalogForUsers({user_ids, catalog_id, enabled, cascade}) {
    return fetch(process.env.REACT_APP_WLS_API_URL + '/api/catalog/for_users', 
        {method: 'PUT',
         mode: 'cors',
         credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(
                {
                "catalog_id": catalog_id,
                "user_ids": user_ids,
                "action": enabled ? "enable" : "disable",
                "cascade": cascade
                }
            )
        }
    ).then(r=> {
        if (r.status === 401)
            window.location.href = '/login';
        return r.json()
    });
}