import React, {useState, useEffect, useRef} from 'react';

import { uploadColors } from 'library/management/upload_images.js';
import { getTaskStatus } from 'library/management/task_status.js';
import { connect } from 'react-redux';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null && delay !== undefined) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function mapStateToProps(state, ownProps) {
  const { dispatch } = state;
  return { dispatch: dispatch, ...ownProps }
}

export function UploadColorsModal({dispatch, active, onClose}) {
  const [userFields, setUserFields] = useState({});
  const [uploadStatus, setUploadStatus] = useState({"status": "none"});

  const handleUploadStatus = () => {
    getTaskStatus(`task_${uploadStatus.task_id}`).then((e) => {
      if (e.details.status === "done") {
        setUploadStatus({"status": "none"});
        dispatch({type:"SHOW_MESSAGE", payload: {message: "Uploaded images!", severity: "success"}});
      } else if (e.details.status === "WIP") {
        setUploadStatus({...uploadStatus, "status": "WIP", "progress": parseInt(e.details.progress)});
      } else if (e.details.status === "failed") {
        setUploadStatus({"status": "none"});
        dispatch({type:"SHOW_MESSAGE", payload: {message: `Error: ${e.details.error}`, severity: "error"}});
      }
    })
  }

  // Update timer
  useInterval(() =>{handleUploadStatus()}, uploadStatus.timer);

  
  const handleAdd = (e) => {
    e.preventDefault();
    setUploadStatus({"status": "uploading"});
    uploadColors(userFields.files).then((e) =>{
        if (e.status === "success") {
            setUserFields({});
            setUploadStatus({"status": "uploading", timer: 5000, task_id: e.task_id, progress: 0});
            //console.log("Sending task with id", e.task_id);
        } else {
            setUserFields({})
            setUploadStatus({"status": "none"})
            dispatch({type:"SHOW_MESSAGE", payload: {message: `Error uploading: ${e.error}`, severity: "error"}})
        }
    }).catch((err) =>{
        setUploadStatus({"status": "none"})
        dispatch({type:"SHOW_MESSAGE", payload: {message: "error", severity: "error"}})
    })
  }
  function handleFileAdded(event) {
    event.preventDefault();
    if (event.target.files.length === 0) {return;}
    let files = event.target.files;
    setUserFields({"files": files});
  }

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload colors</DialogTitle>
        {uploadStatus.status === "none" && 
        <form>
            <DialogContent>
            <TextField
              label="Load colors"
              type="file"
              onChange={handleFileAdded}
              InputLabelProps={{shrink: true}}
              inputProps={{"accept": "image/jpeg", "multiple": true}}
            />
            
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="default">
                Cancel
              </Button>
              <Button 
                type={"submit"}
                color="primary"
                onClick={handleAdd}
                disabled={userFields.files === undefined}
              >
                Update
              </Button>
            </DialogActions>
          </form>
        }
          {uploadStatus.error !== undefined && 
            <p>{uploadStatus.error}</p>

          }
          {uploadStatus.status === "uploading" && 
            <DialogContent>
            <DialogContentText>
              Uploading file, will start processing shortly
            </DialogContentText>
            </DialogContent>
          }
          {uploadStatus.status === "WIP" && 
            <DialogContent>
            <DialogContentText>
              Processing, done {uploadStatus.progress}%
            </DialogContentText>
            <LinearProgress variant="determinate" value={uploadStatus.progress} />
            </DialogContent>
          }
      </Dialog>
    )
}
export default connect(mapStateToProps)(UploadColorsModal)