import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: theme.wls.mainBg.primary,
    flexWrap: "wrap",
  },
  text_h1:{
    marginTop:"0",
    padding: "1em",
    fontFamily:theme.palette.typography,
    color: theme.wls.texts.title,
    fontSize:"20px",
  },
  form_container:{
    display:"flex",
    justifyContent:"center",
    flexWrap: "wrap",
  },
  form_text_field:{
    borderBottom:"2px solid black",
    marginBottom:".5em",
    fontSize:"12px",
  },
  form_left: {
    width: "40%",
    margin: "0 5%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('md')]: {
      width:"100%",
    },
  },
  form_right: {
    width: "40%",
    margin: "0 5%",
    [theme.breakpoints.down('md')]: {
      width:"100%",
    },
  },
  button:{
    width: "100%",
    margin:"1em 0",
    [theme.breakpoints.down('md')]: {
      marginTop:"2em",
    },
  },
  select_container:{
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    margin:"1em 0",
    fontSize:"12px",
    '& > div':{
      width:"100%",
    }
  },
  radio_button_container:{
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    flexDirection:"Row",
    margin:"1em 0 0 0",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  send_email_checkbox: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    margin: '0 0 0 0',
    '& > span': {
      marginLeft: "-11px",
      marginRight: "11px",
    },
  },
  wh_pricelist: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    margin: '1em 0',
    paddingRight: '1em',
    alignItems: 'center',
    '& .MuiInputLabel-root': {
      //marginLeft: "-11px",
      marginRight: "1em",
    },
    '& .MuiInput-root': {
      //marginLeft: "-11px",
      marginRight: "1em",
    },
  }
}));
