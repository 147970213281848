import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper
} from '@material-ui/core';
import WLSTable from 'components/WLSTable';
import { getOrdersFor } from 'library/order/get';
import {cardStyles} from './style';


const dateString = (source) => {
	let date = new Date(source * 1000);
	return date.toISOString().slice(0, 10);
};

export default function UserOrders({user, onUpdate}) {
  const classes = cardStyles();
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    getOrdersFor(user.id).then(e=>{
    	setOrders(e.orders);
    });
  }, [user]);
  
  const columns = [
    { id: 'name', align: "left", disablePadding: true, label: 'Catalog', rowContent: (c) => (
      <a href={`/linesheet/${c.id}`}>{c.catalog}</a>
      ) },
    { id: 'catalog', align: "left", disablePadding: true, label: 'Amount', rowContent: (c) => (
      <a href={`/linesheet/${c.id}`}>{c.total_price !== undefined ? c.total_price.toFixed(2) : "NA"} {c.currency}</a>
      ) },
    { id: 'created', align: "left", disablePadding: true, label: 'Date', rowContent: (c) => (
    	<p>{c.created !== undefined ? dateString(c.created) : "NA"}</p>
    	) },
  ];

  return (
    <Paper
      elevation={0}
      className={classes.root}
    >
      <Box className={classes.headerBox} display="flex">
        <p className={classes.headerTitle}>Latest orders</p>
      </Box>
      <WLSTable 
        classes={classes}
        columns={columns}
        rows={orders}
        shouldShowRow={e=>(true)}
        order_by="-created"
      />
    </Paper>
  );
};

UserOrders.propTypes = {
  user: PropTypes.object,
};
