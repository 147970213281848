import React, { useEffect } from 'react';
import { Paper, CircularProgress, Link} from '@material-ui/core';
import WLSTable from 'components/WLSTable.jsx';
import {OrderActionMenuSimple} from './OrderActionMenuSimple';
import {dateFromTS, sapColumnFor} from 'components/order_list/helpers';
import { useStyles } from './style';
import {notnull} from 'library/wls_helper';
import { connect } from 'react-redux';
import clsx from 'clsx';
import CopyOrderModal from './CopyOrderModal';
import DeleteOrderModal from './DeleteOrderModal';
import EditOrderAddressSOModal from './EditOrderAddressSOModal';
import ReturnOrderToSapModal from './ReturnOrderToSapModal';
import {send_to_sap} from 'library/order/update';

function mapStateToProps(state, ownProps) {
  const { dispatch } = state;
  const {loaded, orders, filter} = ownProps;
  return {loaded: loaded, orders: orders, filter: filter, dispatch: dispatch};
}

function OrderListTable({loaded, orders, filter, dispatch}) {
  const classes = useStyles();
  const [menuElement, setMenuElement] = React.useState({element: null, order: null});
  const [modal, setModal] = React.useState({});

  useEffect(()=>{

  },[loaded, orders, filter])

  const rowFilter = (order) => {
    if (filter.query) {
      let lower_case_query = filter.query.toLowerCase();
      let order_as_string = JSON.stringify(order).toLowerCase();
      if (!order_as_string.includes(lower_case_query))
        return false;
    }
    return true;
  };

  const salesOrgContent = (order) => (
    (notnull(order.channel) && notnull(order.sales_organization))
    ? `${order.sales_organization}/${order.channel}`
    : null
  );

  const didClickMenu = (event, order) => {
    //store.dispatch({type: "SET_MENU_ELEMENT", payload: {element: event.currentTarget, order: order}})
    setMenuElement({element: event.currentTarget, order: order});
    //event.currentTarget.classList.toggle('OrderListActiveButton');
  }


  const sendToSap = (order) => {
    if (order.catalog_type === 'return') {
      setModal({name: "return_tosap", order: order});
      return;
    }
    send_to_sap(order.id).then(e=>{
      if (e.status === "success"){
        dispatch({type: "SHOW_MESSAGE", payload: {severity: "success", message: "Order sent to SAP"}});
      } else {
        dispatch({type: "SHOW_MESSAGE", payload: {severity: "error", message: e.error}});
      }
    })
  }
    
  const onMenuItemClick = (action, order) => {
    setMenuElement({});
    if (action === "copy_order") {
      setModal({name: "copy_order", order: order});
    } else if (action === "delete_order") {
      setModal({name: "delete_order", order: order});
    } else if (action === "edit_address") {
      setModal({name: "edit_address", order: order});
    } else if (action === "send_to_sap") {
      sendToSap(order);
    } else {
      dispatch({type: "SHOW_MESSAGE", payload: {severity: "error", message: `Action ${action} not implemented (yet)`}});
    }
  }

  const onModalClose = () => {
    setModal({});
    dispatch({type: "RELOAD_TABLE"});
  }

  const setMessage = (message) => {
    dispatch({type: "SHOW_MESSAGE", payload: message});
  }

  const columns = [
    { disablePadding: true, id: 'user', align: "left", label: 'Customer', rowContent: (c) => (<Link href={"/admin/user/" + c.customer.id}>{c.customer.name}</Link>) },
    { disablePadding: true, id: 'status', size: "small", align: "left", label: 'Status', rowContent: sapColumnFor},
    { disablePadding: true, id: 'catalog', align: "left", label: 'Catalog' },
    { disablePadding: true, id: 'seller', align: "left", label: 'Agent' },
    { disablePadding: true, id: 'sales_org', size: "small", align: "left", label: 'SO', rowContent:  salesOrgContent},
    { disablePadding: true, id: 'address_id', size: "small", align: "left", label: 'Ship' },
    { disablePadding: true, id: 'items_count', size: "small", align: "left", label: 'Items' },
    { disablePadding: true, id: 'total_price', size: "small", align: "right", label: 'Amount', rowContent: (c) => (`${c.total_price.toFixed(2)} ${c.currency}`) },
    { disablePadding: true, id: 'created', align: "right", label: 'Created', rowContent: (c) => (dateFromTS(c.created)) },
    { disablePadding: true, id: 'actions', size: "small", align: "center", label: 'Actions', hideSort: true, rowContent: (c) => (
      <button className={clsx(classes.menuButton, {'OrderListActiveButton': false })} onClick={e=>didClickMenu(e, c)} aria-haspopup="true" aria-controls="menu"><span /></button>
    )},
  ];

  return (
    <div className={classes.table_container_bg}>
      <Paper hidden={loaded} className={classes.loading_spinner}>
        <CircularProgress />
      </Paper>
      <WLSTable
        hidden={!loaded}
        classes={classes}
        rows={orders}
        columns={columns}
        shouldShowRow={rowFilter}
      />
      <OrderActionMenuSimple menuElement={menuElement.element} handleMenuClose={()=>{setMenuElement({})}} order={menuElement.order} onItemClick={onMenuItemClick} />
      <CopyOrderModal open={modal.name === 'copy_order'} order={modal.order} onClose={onModalClose} setMessage={setMessage}/>
      <DeleteOrderModal open={modal.name === 'delete_order'} order={modal.order} onClose={onModalClose} setMessage={setMessage} />
      <ReturnOrderToSapModal open={modal.name === 'return_tosap'} order={modal.order} onClose={onModalClose} setMessage={setMessage} />
      <EditOrderAddressSOModal open={modal.name === 'edit_address'} order={modal.order} onClose={onModalClose} setMessage={setMessage} />
    </div>
  );
}
export default connect(mapStateToProps)(OrderListTable)