import React, { useState } from 'react';
import { FormGroup, TextField, Button } from '@material-ui/core';
import { doLogin } from 'library/user/auth'
import { useNavigate } from "react-router-dom";
import {Logo} from "./logo";
import { useStyles } from "./Style";


function Login(props) {
	const classes = useStyles();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	let history = useNavigate();

  const onLoginResult = response => {
		/*
		Login request has a response.
		200 -> Success
		404 -> Wrong credentials
		*/
		switch (response.status) {
			case "success":
				history('/admin/orders/list')
				break;
			case "error":
				setErrorMessage(response.error)
				break;
			default: break;
		}
  }

  function handleSubmit(event) {
		event.preventDefault();
		doLogin(username, password).then(onLoginResult);
  }

	let errorBox = errorMessage !== '' ? <p className={classes.text_error}>{errorMessage}</p> : "";
  
  return (
	<div className={classes.main}>
		<div className={classes.left_column}>
			<Logo className={classes.logo}/>
			<p className={classes.text_base}>Dashboard Login</p>
		</div>
		<div className={classes.right_column}>
			<div className={classes.right_column_child}>
				<p className={classes.text_big}>Welcome back!<span className={classes.text_block}>Please login to your account.</span></p>
					<form className={classes.form} onSubmit={handleSubmit}>
						<FormGroup>
							<TextField label="Username" onChange={e => setUsername(e.target.value)} />
							<TextField label="Password" type="password" onChange={e => setPassword(e.target.value)} />
							<Button className={classes.button} type="submit" onClick={handleSubmit}>
								Login
							</Button>
						</FormGroup>
		  			</form>
			{errorBox}
			</div>
		</div>
	</div>
  );
}

export default Login;
