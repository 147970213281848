
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
	main:{
		display:"flex",
		[theme.breakpoints.down('md')]: {
			flexWrap:"wrap",
			flexDirection:"column",
			justifyContent:"center",
			alignItems:"center",
			height:"100vh",
		},
		[theme.breakpoints.between('sm', "md")]: {
		width:"75vw",
		margin: "0 auto",
		},
	},
	left_column:{
		backgroundColor:theme.wls.navigation.backgroundColor,
		height:"calc(100vh - 4em)",
		width:"calc(30vw - 4em)",
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
		flexDirection:"column",
		padding:"2em",
		[theme.breakpoints.down('md')]: {
			backgroundColor:theme.palette.primary.contrastText,
			height:"auto",
			width:"calc(100vw - 4em)",
			paddingBottom:"0",
		},
		[theme.breakpoints.between('sm', "md")]: {
		width:"calc(100% - 4em)",
		height:"auto",
		},
	},
	logo:{
		fill:theme.palette.primary.contrastText,
		width: "80%",
		height: "auto",
		[theme.breakpoints.down('md')]: {
			width: "80%",
			fill:theme.wls.navigation.backgroundColor,
		},
	},
	right_column:{
		height:"calc(100vh - 4em)",
		width:"calc(70vw - 4em)",
		padding:"2em",
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
		flexDirection:"column",
		[theme.breakpoints.down('md')]: {
			height:"auto",
			width:"calc(100vw - 4em)",
		},
		[theme.breakpoints.between('sm', "md")]: {
		width:"calc(100% - 4em)",
		height:"auto",
		},
		'& input.MuiInputBase-input.MuiInput-input':{
			backgroundColor: "transparent",
			borderBottom:"2px solid black",
		},
		'& label.Mui-focused':{
			color:theme.palette.primary.main,
		},
		'& input.Mui-focused':{
			color:theme.wls.navigation.backgroundColor,
			borderBottom:theme.palette.details,
		}
	},
	right_column_child:{
		width:"400px",
		[theme.breakpoints.down('md')]: {
			width:"calc(100vw - 4em)",
		},
		[theme.breakpoints.between('sm', "md")]: {
		width:"calc(100% - 4em)",
		height:"auto",
		},
	},
	text_base:{
		color:theme.palette.primary.contrastText,
	},
	text_big:{
		textAlign:"center",
		fontSize:"25px",
		borderBottom:"1px solid #e0e0e0",
		paddingBottom:"2em",
		marginBottom:"2em",
		width:"100%",
		fontFamily:"MinisterStd",
		[theme.breakpoints.down('md')]: {
			fontSize:"20px",
		},
	},
	text_block:{
		display:"block",
	},
	text_error:{
		textAlign:"center",
		border:"2px solid red",
		padding:".25em",
		marginBottom:"0",
	},
	form:{
		width:"100%",
	},
	button:{
		backgroundColor:theme.wls.navigation.backgroundColor,
		color:theme.palette.primary.contrastText,
		borderRadius:"0",
		marginTop:"4em",
		[theme.breakpoints.down('md')]: {
			marginTop:"2em",
		},
		'&:hover':{
			backgroundColor:theme.palette.details,
		}
	},
}));