import React,{ useEffect } from 'react';
import { getCatalogListAdmin } from 'library/catalog/get';
import { savePermissions } from 'library/catalog/update';
import { useStyles } from './Style';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Tooltip} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

export default function CatalogEnabler() {
  const classes = useStyles();
	const [catalogList, setCatalogList] = React.useState([]);
  const [filter, setFilter] = React.useState({active: true, text: ""});

	useEffect(() => {
    getCatalogListAdmin().then(setCatalogList);
	},[]);

  function didChangeWLS(catalog_id, new_status) {
    let catalog = catalogList.filter(x => x.id === catalog_id).pop();
    catalog.wls_enabled = new_status;

    setCatalogList(catalogList.map(x =>
      x.id === catalog_id ? catalog : x
    ));

    savePermissions(catalog_id, catalog.wls_enabled, catalog.ls_enabled, catalog.is_current).then(response => {
      if (response.status === "error" ) {
        alert(response.error);
      }
    });
  }
  function didChangeLS(catalog_id, new_status) {
    let catalog = catalogList.filter(x => x.id === catalog_id).pop();
    catalog.ls_enabled = new_status;

    setCatalogList(catalogList.map(x =>
      x.id === catalog_id ? catalog : x
    ));

    savePermissions(catalog_id, catalog.wls_enabled, catalog.ls_enabled, catalog.is_current).then(response => {
      if (response.status === "error" ) {
        alert(response.error);
      }
    });
  }
  function didChangeIsEnabled(catalog_id, new_status) {
    let catalog = catalogList.filter(x => x.id === catalog_id).pop();
    catalog.is_current = new_status;

    setCatalogList(catalogList.map(x =>
      x.id === catalog_id ? catalog : x
    ));

    savePermissions(catalog_id, catalog.wls_enabled, catalog.ls_enabled, catalog.is_current).then(response => {
      if (response.status === "error" ) {
        alert(response.error);
      }
    });
  }

  const passes_filter = ((catalog) => {
    var show = true;
    if (filter.active) {
      show = (catalog.is_current === filter.active);
    }
    if (filter.text !== "") {
      show = catalog.name.includes(filter.text);
    }
    return show;
  });

	return (
    <div>
      <h1 className={classes.text_h1}>Visibility</h1>
      <div className={classes.filter_container}>
        <ToggleButton value="current_catalog" className={classes.filter_current_catalog} aria-label="centered" onChange={e=>{setFilter({...filter, active: !filter.active})}}>
          <Tooltip title="Current catalogs" aria-label="add">
            { filter.active ? 
            <BookmarkIcon style={{color: "#5995c9"}} />
            :
            <BookmarkBorderIcon style={{color: "#5995c9"}} />
            }
          </Tooltip>
        </ToggleButton>
        <TextField 
          label="Search"
          className={classes.padding_text_field}
          onChange={e=>{setFilter({...filter, text: e.target.value})}}
        />
      </div>
      <div className={classes.form_container}>
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader={true}>
            <TableHead className={classes.table_head}>
              <TableRow>
                <TableCell align="left">Catalog</TableCell>
                <TableCell align="center">Visible by default</TableCell>
                <TableCell align="center">WLS</TableCell>
                <TableCell align="center">App</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {catalogList.filter(passes_filter).map((catalog, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {catalog.name}
                  </TableCell>
                  <TableCell align="center">
                    <ToggleButton value="current_catalog" aria-label="centered" onChange={e=>{didChangeIsEnabled(catalog.id, !catalog.is_current)}}>
                      <Tooltip title="Current catalogs" aria-label="add">
                        { catalog.is_current ? 
                        <BookmarkIcon style={{color: "#5995c9"}} />
                        :
                        <BookmarkBorderIcon style={{color: "#5995c9"}} />
                        }
                      </Tooltip>
                    </ToggleButton>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={catalog.wls_enabled} onChange={e => didChangeWLS(catalog.id,e.target.checked)} color="primary"/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={catalog.ls_enabled} onChange={e => didChangeLS(catalog.id,e.target.checked)} color="primary"/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
