import React from 'react';
import { Tooltip, IconButton} from '@material-ui/core';
import { useStyles } from './style';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchField from 'components/SearchField';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FilterListIcon from '@material-ui/icons/FilterList';
import Badge from '@material-ui/core/Badge';
import clsx from 'clsx';
import {WLSContext} from 'wls_context.js';

export default function OrderListFilterBar({filter, onFilterChange, openModal, onDownloadExcel}) {
  const classes = useStyles();
  const {ctx} = React.useContext(WLSContext);
  let perms = ctx.user ? ctx.user.roles : [];
  
  React.useEffect(() => {
  },[]);

  const didChangeQuery = e => {
    if (!e.target.value) {
      return onFilterChange({...filter, query: null});
    }
    onFilterChange({...filter, query: e.target.value});
  };

  const didChangeCurrentCatalog = (e => {
    onFilterChange({...filter, current_catalog: !filter.current_catalog});
  });
  const didChangeFilter = ((e,v) => {
    if (v === filter.sap_status) { // Toggle function
      return onFilterChange({...filter, sap_status: null});
    }
    onFilterChange({...filter, sap_status: v});
  });

  let num_filters = 0;
  if (filter.catalogs.length > 0) {
    num_filters+=1;
  }
  if (filter.begin !== undefined) {
    num_filters+=1;
  }
  if (filter.end !== undefined) {
    num_filters+=1;
  }
  if (filter.seasons.length > 0) {
    num_filters+=1;
  }

  return (
    <div className={classes.filter_container_horizontal}>
      <div className={classes.searchFilterBox}>
        <SearchField
          onChange={didChangeQuery}
        />
        <ToggleButtonGroup className={classes.toggleButtonGroup} value={filter.sap} exclusive onChange={didChangeFilter} >
          <ToggleButton value="all" aria-label="left aligned"  className={clsx(filter.sap_status==="all" ? classes.icon_filter_active : null)}>
            <Tooltip title="All orders" aria-label="add">
            <ClearAllIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="open" aria-label="left aligned" className={clsx(classes.icon_open, filter.sap_status==="open" ? classes.icon_filter_active : null)}>
            <Tooltip title="Only open" aria-label="add">
              <BlurCircularIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="finished" aria-label="left aligned" className={clsx(filter.sap_status==="finished" ? classes.icon_filter_active : null)}>
            <Tooltip title="Only finished" aria-label="add">
              <CheckCircleOutlineIcon style={{color: "#4caf50"}}/>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="sent" aria-label="left aligned" className={clsx(filter.sap_status==="sent" ? classes.icon_filter_active : null)}>
            <Tooltip title="Only sent" aria-label="add">
              <OpenInNewIcon style={{color: "#d0cfcf"}}/>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="in_sap" aria-label="centered"  className={clsx(filter.sap_status==="in_sap" ? classes.icon_filter_active : null)}>
            <Tooltip title="Only in SAP" aria-label="add">
            <OpenInBrowserIcon style={{color: "#5995c9"}} />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton value="current_catalog" aria-label="centered" onChange={didChangeCurrentCatalog}>
          <Tooltip title="Current catalogs" aria-label="add">
            { filter.current_catalog ? 
            <BookmarkIcon style={{color: "#5995c9"}} />
            :
            <BookmarkBorderIcon style={{color: "#5995c9"}} />
            }
          </Tooltip>
        </ToggleButton>
        <Tooltip title="More filters">
          <Badge badgeContent={num_filters} color="primary">
          <IconButton color="primary" component="span" onClick={openModal}>
            <FilterListIcon />
          </IconButton>
          </Badge>
        </Tooltip>
      </div>
      {perms.includes("agent") || perms.includes("admin") ?
      <div className={classes.dateFilterBox}>
        <IconButton className={classes.downloadListIcon} onClick={onDownloadExcel}>
          <GetAppIcon />
        </IconButton>
      </div>
      :
      <></>
      }
    </div>
  );
}
