import React, {useEffect, useState} from 'react';
import {getUsersList} from 'library/user/get';
import { getAgents } from 'library/user/get';

// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {FormGroup, FormLabel, Radio, RadioGroup, Select} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CustomerSelector from "../../CustomerSelector";

export default function DeleteUserModal({active, onDelete, onClose, classes, user}) {

    const [ userSelectedtoAssign, setUserSelectedtoAssign] = useState({id: null, name: "anyone" });
    const [assign, setAssign] = useState([]);
    // eslint-disable-next-line
    const [userList, setUserList] = useState([]);
    const [userAgentList, setAgentList] = useState([]);
    const [isOther, setIsOther] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [customer_selector, setCustomerSelector] = useState(false);

    const handleAdd = () => {
        let toDelete = {
            "id": user.id,
            "handover": userSelectedtoAssign.id,
            "delete_orders": false
        };
        onDelete(toDelete);
    }

    const handleChangeAssign = (value) => {
        let name = "me";
        if(value === "other") {
            setIsOther(true);
        }else {
            setAssign(value);
            setIsOther(false);
            if(!value){
               name = "anyone"
            }
            setUserSelectedtoAssign({id: value, name});
        }
    };

    const didCloseCustomerSelector = (customer) => {
        if (customer) {
            setIsOther(true);
            setUserSelectedtoAssign({id: customer.id, name: customer.name });
            setAssign(customer.id);
        }else {
            setIsOther(false);
        }
        setCustomerSelector(false);
    }

    useEffect(()=>{
        if(isOther){
            getUsersList().then(setUserList);
        }
    },[isOther]);

    useEffect(()=>{
         if(user.roles.includes("agent")) {
             setIsAgent(true);
             getAgents().then(setAgentList);
         }// eslint-disable-next-line
    }, []);

  return (
      <Dialog open={active} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete user</DialogTitle>
        <DialogContent>
          <DialogContentText>
              To apply changes push button <b>"Delete"</b>
          </DialogContentText>
            { ! isAgent &&
            <FormControl component="fieldset">
                <FormLabel component="legend">Assigns orders to...</FormLabel>
                <RadioGroup aria-label="assign" name="assign" value={assign} onChange={e => handleChangeAssign(e.target.value) }>
                    {/*<FormControlLabel value={ctx.user.id} control={<Radio />} label="Me" />*/}
                    <FormControlLabel value={""} control={<Radio />} label="No assign" />
                    <FormControlLabel value="other"
                                      onClick={e=>{setCustomerSelector(true)}}
                                      checked={isOther} control={<Radio />}
                                      label="Other user" />
                </RadioGroup>
            </FormControl>
            }
            {!isAgent &&
            <>
                <DialogContentText>
                    After deleting the orders will be assigned to  {userSelectedtoAssign.name}
                </DialogContentText>
            </>
            }
            { isAgent &&
                <>
                <FormGroup>
                    <FormControl className={classes.formControl}>
                    <InputLabel >Select agent to assign orders</InputLabel>
                    <Select name={"orders-to-agent"}>
                        {userAgentList.map( agent => (
                            <MenuItem key={agent.id} value={agent.id} > {agent.name} </MenuItem>
                        ) )}
                    </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                    <InputLabel >Select agent to assign users</InputLabel>
                    <Select name={"users-to-agent"}>
                        {userAgentList.map( agent => (
                            <MenuItem key={agent.id} value={agent.id} > {agent.name} </MenuItem>
                        ) )}
                    </Select>
                    </FormControl>
                </FormGroup>
                </>
            }
          {/* <FormControlLabel control={
            <PrimaryCheckbox
              name="Assign-me"
              color="primary"
            />} label="Assign me" className={classes.roleCheckbox}/> */}

        <CustomerSelector  show={customer_selector} onClose={didCloseCustomerSelector}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
}