import React,{ useEffect } from 'react';
import { getCatalogList } from 'library/catalog/get';
import { reuploadCatalog } from 'library/catalog/update';
import { FormGroup, TextField, Button } from '@material-ui/core';
import { useStyles } from './Style';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Paper, CircularProgress} from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

export default function CatalogReUploader() {
  const classes = useStyles();
  const [catalogList, setCatalogList] = React.useState([]);
  const [selected_catalog, setSelectedCatalog] = React.useState("");
  const [service_date, setServiceDate] = React.useState("");
  const [drop, setDrop] = React.useState("");
  const [catalog_file, setCatalogFile] = React.useState();
  const [is_uploading, setIsUploading] = React.useState(false);
  const [is_current, setIsCurrent] = React.useState("false");
  const [catalog_name, setCatalogName] = React.useState("First select catalog");
  const [catalog_type, setCatalogType] = React.useState("initial");


  useEffect(() => {
    setIsUploading(true);
		getCatalogList().then(e => {
      setIsUploading(false);
      setCatalogList(e);
    });
	},[]);

	function handleSubmit(event) {
		event.preventDefault();
    setIsUploading(true);
    reuploadCatalog(selected_catalog, catalog_file, service_date, catalog_name, catalog_type, is_current, drop ).then(response => {
      setIsUploading(false);
      if (response.status === "success") {
        alert("Awesome! Catalog was updated");
      } else if (response.status === "error") {
        alert(response.error);
      } else {
        alert("Problem updating the catalog, try again later!");
      }
    })
	}
	function handleFileAdded(event) {
		event.preventDefault();
		if (event.target.files.length === 0) {return;}
		let file = event.target.files[0];
    setCatalogFile(file);
	}

  function didChangeServiceDate(event) {
    if (event.target.value === null || event.target.value === undefined || event.target.value === ""){
      setServiceDate(null);
      return;
    }
    let date = (new Date(event.target.value)).getTime() / 1000;
    setServiceDate(date);
  }

  function didChangeCatalog(event) {
    setSelectedCatalog(event.target.value); // Catalog ID
    catalogList.map(catalog => {
      if (catalog.id === event.target.value) {
        setServiceDate(catalog.service_date ? catalog.service_date : null);
        setCatalogName(catalog.name);
        setDrop(catalog.drop);
        setCatalogType(catalog.catalog_type);
        if (catalog.is_current !== null && catalog.is_current !== undefined){
          setIsCurrent(catalog.is_current.toString());
        } else {
          setIsCurrent("false");
        }
        return 0;
      }
      return 1;
    });
  }
  
  let service_date_str = (service_date !== undefined && service_date !== null) ? new Date(service_date * 1000).toISOString().slice(0, 10) : null;
	return (
    <div className={classes.root}>
      <h1 className={classes.text_h1}>Edit catalog</h1>
      <div className={classes.form_container}>
        <Paper hidden={!is_uploading}>
          <h2>Loading</h2>
          <CircularProgress />
        </Paper>
        <form hidden={is_uploading} className={classes.form} onSubmit={handleSubmit}>
        <FormGroup>
          <FormControl className={classes.formControl}>
            <InputLabel >Catalog</InputLabel>
            <Select value={selected_catalog} onChange={didChangeCatalog}>
              {catalogList.map( catalog => (
                  <MenuItem key={catalog.id} value={catalog.id}>{catalog.name} ({catalog.num_items} products)</MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField label="Name" className={classes.form_text_field} value={catalog_name} onChange={e => setCatalogName(e.target.value)} />
          <RadioGroup className={classes.radio_button_container} value={catalog_type}  onChange={e => setCatalogType(e.target.value)} >
            <FormControlLabel value="initial" control={<Radio color="primary" />} label="Initial" />
            <FormControlLabel value="repo" control={<Radio color="primary" />} label="Reposition" />
            <FormControlLabel value="return" control={<Radio color="primary" />} label="Return" />
          </RadioGroup>
          <TextField label="Drop" className={classes.form_text_field} value={drop} onChange={e => setDrop(e.target.value)} />
          <RadioGroup className={classes.radio_button_container} value={is_current}  onChange={e => setIsCurrent(e.target.value)} >
            <FormControlLabel value="true" control={<Radio color="primary" />} label="Visible by default" />
            <FormControlLabel value="false" control={<Radio color="primary" />} label="Not visible by default" />
          </RadioGroup>
          <TextField label="Default service date" type="date" value={service_date_str} onChange={didChangeServiceDate} className={classes.textField} InputLabelProps={{shrink: true,}}/>
          <TextField className={[classes.form_text_field].join(" ")} label="Load excel" type="file" onChange={handleFileAdded} InputLabelProps={{shrink: true}} inputProps={{"accept": ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}} />
          <Button variant="contained" className={classes.button} type="submit" onClick={handleSubmit}>
            Update!
          </Button>
        </FormGroup>
        </form>
      </div>
    </div>
  );
}
