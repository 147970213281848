
import {apiCall} from 'library';

export function updateOrder(order) {
	return apiCall('/api/order/update', 
    	{method: 'PUT',
         body: JSON.stringify(
                {
                "id": order.id,
                "lines": order.lines,
                "wanted": order.wanted,
                "service_date": order.service_date,
                }
            )
    	}
    );
}

export function updateOrderFinished(id, fihished) {
    return apiCall('/api/order/finish',
        {method: 'PUT',
            body: JSON.stringify(
                {
                    "id": id,
                    "customer_finished":  fihished,
                }
            )
        }
    );
}

export function send_to_sap(order_id) {
    return apiCall('/api/order/send_sap', 
        {method: 'POST',
         body: JSON.stringify(
                {
                "id": order_id
                }
            )
        }
    );
}

export function unblockOrder(order_id) {
    return apiCall('/api/order/unlock', 
        {method: 'PUT',
         body: JSON.stringify(
                {
                "id": order_id
                }
            )
        }
    );
}

export function updateOrderAddress({order_id, name, address, channel, sales_organization}) {
    let payload = {
                "id": order_id,
                "name": name,
                };
    if (address)
        payload.address = address;
    if (channel && sales_organization) {
        payload.channel = channel
        payload.sales_organization = sales_organization
    }
    return apiCall('/api/order/update_address', 
        {method: 'PUT',
         body: JSON.stringify(payload)
        }
    );
}