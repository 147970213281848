import {apiCall} from 'library';

export async function createUser(name, email, sap_id, catalogs, is_wholesale, send_email, roles, extra_info, warehouse, pricelist) {
    return apiCall('/api/user',
    	{method: 'POST',
         body: JSON.stringify(
                {
                "name": name,
                "email": email,
                "is_wholesale": is_wholesale,
                "send_email": send_email,
                "sap_id": sap_id,
                "roles": roles,
                "catalogs": catalogs,
                "warehouse": warehouse,
                "pricelist": pricelist,
                "extra_info": extra_info
                }
            )
    	}
	);
}

export function uploadBulk(file) {
    const FD  = new FormData();
    FD.append("file", file);
    return fetch(process.env.REACT_APP_WLS_API_URL + '/api/user/bulk',
        {method: 'POST',
         mode: 'cors',
         credentials: "include",
         body: FD
        }
    ).then(r=> {
        return r.json()
    });
}
